import { Module } from 'vuex';
import { RootState } from '@/store/type';
import { CompanyState } from './type';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';

const state: CompanyState = {
  companies: [],
  myCompany: null
}

export const company: Module<CompanyState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
