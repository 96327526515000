import GraphQL from '@/interfaces/graphql';
import { listSystemOperationHistories } from '@/graphql/queries';
import { createSystemOperationHistories } from '@/graphql/mutations';
import axios from 'axios';
import { AuthUser } from '@/models/internal/user.model';

export interface LoggingOperationHistoryParam {
  user: AuthUser;
  viewName: string;
  viewId: string;
  operationName: string;
  operationId: string;
  detail?: object;
}

export const systemOperationHistories = {
  namespaced: true,
  state: { error: null, SystemOperationHistories: [] },
  mutations: {
    setSystemOperationHistories(state: any, payload: any) {
      state.SystemOperationHistories = payload;
    },
    appendSystemOperationHistory(state: any, payload: any) {
      state.SystemOperationHistories.push(payload);
    }
  },
  actions: {
    async getSystemOperationHistories({ commit }: any) {
      const SystemOperationHistories = await GraphQL.query(listSystemOperationHistories);

      //convert from timestamp to readable date
      SystemOperationHistories.data.listSystemOperationHistories.items.map(
        (sysOperation: any) => {
          sysOperation.CreatedAt = new Date(
            sysOperation.CreatedAt * 1000
          ).toLocaleString('ja-JP');
        }
      );

      commit(
        'setSystemOperationHistories',
        SystemOperationHistories.data.listSystemOperationHistories.items
      );
    },
    async createSystemOperationHistories({ commit }: any, params: any) {
      console.log(params);

      const SystemOperationHistories = await GraphQL.query(createSystemOperationHistories, { input: params });
      console.log("SystemOperationHistories");
      console.log(SystemOperationHistories);

      commit(
        'appendSystemOperationHistory',
        SystemOperationHistories.data.createSystemOperationHistories
      );
    },
    /** システム操作履歴を保存する */
    async loggingOperationHistory(
      { commit }: any,
      value: LoggingOperationHistoryParam
    ) {
      let clientIpAddress: any | null = null;
      try {
        const res = await axios.get('https://api.ipify.org/?format=json');
        clientIpAddress = res.data.ip;
      } catch (ex) {
        // console.error(ex);
        //IPアドレスが取得できないことは問題とはしない。
      }
      const detail = {
        clientIpAddress,
        ...value.detail
      };

      const params = {
        UserId: value.user.id,
        UserMail: value.user.email,
        TimestampMs: new Date().getTime(),
        ViewName: value.viewName,
        ViewId: value.viewId,
        OperationName: value.operationName,
        OperationId: value.operationId,
        Detail: JSON.stringify(detail),
        CreatedAt: Math.floor(new Date().getTime() / 1000.0),
      };

      console.log(params);

      const SystemOperationHistories = await GraphQL.query(createSystemOperationHistories, { input: params });
      console.log("SystemOperationHistories");
      console.log(SystemOperationHistories);

      commit(
        'appendSystemOperationHistory',
        SystemOperationHistories.data.createSystemOperationHistories
      );
    },
  },
  getters: {
    SystemOperationHistories: (state: any) => state.SystemOperationHistories
  },
};
