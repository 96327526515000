









































interface Status {
  open: string;
  closed: string;
  inProgress: string;
}

import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

@Component
export default class DeviceTrouble extends Vue {
  deviceDetail = false;
  headers = [
    { text: "障害状況", value: "Status" },
    { text: "種別", value: "KindLabel" },
    { text: "端末ID", value: "TerminalId" },
    { text: "更新時間", value: "UpdatedAt" },
    { text: "作成時間", value: "CreatedAt" },
  ];

  statusItems: Status = {
    open: "故障",
    inProgress: "故障", //対応ワードが分からないため、一旦故障。
    closed: "解決済み",
  };

  data: Array<any> = [];

  searchTerminalId?: any = "";
  searchTimestampMs?: any = "";
  currentMalfuncDetail: string | null = null;
  currentMalfuncExplanation: string | null = null;

  @Action("terminalMalfunctionHistories/getTerminalMalfunctionHistories")
  getTerminalMalfunctionHistories!: (
    terminalId?: string,
    timestampMs?: string
  ) => void;
  @Getter("terminalMalfunctionHistories/terminalMalfunctionHistories")
  terminalMalfunctionHistories!: any;

  loading = true;

  async created() {
    this.loading = true;
    this.searchTerminalId = this.$route.query.terminal
      ? this.$route.query.terminal
      : undefined;
    this.searchTimestampMs = this.$route.query.time
      ? this.$route.query.time
      : undefined;
    const dataParam: any = {
      terminalId: this.searchTerminalId,
      timestampMs: this.searchTimestampMs,
    };
    this.getTerminalMalfunctionHistories(dataParam);
    this.loading = false;
  }

  openDetail(item: any) {
    this.currentMalfuncExplanation = item.Malfunction.Message;
    this.currentMalfuncDetail = item.Malfunction.Payload;
    this.deviceDetail = true;
  }

  getStatusLabel(value: keyof Status): string {
    if (Object.keys(this.statusItems).includes(value)) {
      return this.statusItems[value];
    }
    return "unknown"; //もし知らないステータスが出てきたら、未知にする。
  }
  convertToLocaleString(value: number): string {
    return new Date(value * 1000).toLocaleString("ja-JP");
  }
}
