import { ActionTree } from 'vuex';
import { RootState } from '@/store/type';
import { GroupState } from './type';
import CommAdminApi from '@/interfaces/commAdminApi';
import {
    UserGroupEntity,
    TerminalGroupEntity,
    UserItem,
    TerminalItem,
} from '@/models/internal/groupEntity.model';
import { User } from '@/models/commadmin/users.model';
import { AuthUser } from '@/models/internal/user.model';
import { Group } from '@/models/commadmin/groups.model';
import { CompanyEntity } from '@/models/internal/companyEntity.model';
import { ShopEntity } from '@/models/internal/shopEntity.model';
import { TerminalEntity} from '@/models/internal/terminalEntity.model';
import { getRollLabel } from '@/models/view/role';

type ProcessContext = {
    rootGetters: any;
};

const getTerminalById = (ctx: ProcessContext, terminalId: string) => {
    const terminals: TerminalEntity[] = ctx.rootGetters['terminals/terminals'];
    const result = terminals.filter((v) => { v.base.Id == terminalId });
    if (result.length == 0) {
        return null;
    }
    return result[0];
}
const getShopById = (ctx: ProcessContext, shopId: string | null) => {
    if (!shopId) return null;
    const shops: ShopEntity[] = ctx.rootGetters['shops/shops'];
    const result = shops.filter((v) => { v.base.Id == shopId });
    if (result.length == 0) {
        return null;
    }
    return result[0];
}
const getUserById = (ctx: ProcessContext, userId: string | null) => {
    if (!userId) return null;
    const users: User[] = ctx.rootGetters['users/users'];
    const result = users.filter((v) => { v.Id == userId });
    if (result.length == 0) {
        return null;
    }
    return result[0];
}

const processTerminalGroupEntity = (ctx: ProcessContext, group: Group) => {
    const terminals: TerminalItem[] = group.Members.Terminals
        ? group.Members.Terminals.map((v) => {
            const terminal = getTerminalById(ctx, v.Id);
            if (terminal && terminal.property) {
                const shop = getShopById(ctx, terminal.property.ShopId);
                return {
                    id: v.Id,
                    name: terminal.property.DisplayName || '',
                    shopId: terminal.property.ShopId || '',
                    shopName: shop ? shop.base.DisplayName : '',
                };
            } else {
                return {
                    id: v.Id,
                    name: `${v.Kind}-${v.Id}`,
                    shopId: '',
                    shopName: '',
                };
            }
        })
        : []
    const entity = {
        base: group,
        id: group.Id,
        name: group.Name,
        terminals,
    } as TerminalGroupEntity;
    return entity;
} // TerminalGroupEntity

const processUserGroupEntity = (ctx: ProcessContext, group: Group) => {
    const users: UserItem[] = group.Members.Users
        ? group.Members.Users.map((v) => {
            const user = getUserById(ctx, v.Id);
            if (user) {
                const shop = getShopById(ctx, user.ShopId);
                return {
                    id: v.Id,
                    name: user.Email || '',
                    role: user.CognitoGroup,
                    roleTitle: getRollLabel(user.CognitoGroup),
                    shopId: user.ShopId || '',
                    shopName: shop ? shop.base.DisplayName : '',
                };
            } else {
                return {
                    id: v.Id,
                    name: '',
                    role: '',
                    roleTitle: '',
                    shopId: '',
                    shopName: '',
                };
            }
        })
        : []
    const entity = {
        base: group,
        id: group.Id,
        name: group.Name,
        users,
    } as UserGroupEntity;
    return entity;
} // UserGroupEntity

const actions: ActionTree<GroupState, RootState> = {
    async fetchGroups({ commit, dispatch, rootGetters }) {
        commit('clearGroups');
        // ログイン中のユーザが参照可能なリソースを読み込む
        await Promise.all([
          dispatch('company/getCompany', null, { root: true }),
          dispatch('users/getUsers', null, { root: true }),
          dispatch('terminals/getTerminals', null, { root: true }),
          dispatch('auth/authAction', null, {root: true}),
        ])

        const companies: CompanyEntity[] = rootGetters['company/companies']
        const authKey = await rootGetters['auth/jwt'];
        const api = new CommAdminApi(authKey);
        const user: AuthUser = rootGetters['auth/user'];

        let userGroups: UserGroupEntity[] = [];
        let terminalGroups: TerminalGroupEntity[] = [];


        for (const company of companies) {
            const groups = await api.getCompanyGroups(company.base.Id);
            for (const group of groups) {
                if (group.Kind == 'Principal') { // 画面表示に使うのは Secondary のみ
                    continue;
                }
                // TODO: 企業なしの扱いチェック
                switch (group.MemberKind) {
                    case 'Users':
                        userGroups.push(processUserGroupEntity({
                            rootGetters,
                        }, group));
                        break;
                    case 'Terminals':
                        terminalGroups.push(processTerminalGroupEntity({
                            rootGetters,
                        }, group));
                        break;
                }
            }
        }
        // ユーザーグループIDがセカンダリ時の制限
        if (user.groupId.startsWith("Secondary")){
          userGroups = userGroups.filter(x => x.id === user.groupId)
          terminalGroups = terminalGroups.filter(x => userGroups.map(y => y.base.RelGroups).flat().includes(x.id))
        }else{
          if (user.role === "ShopManager" || user.role === "ShopUser"){
            userGroups = userGroups.filter(x => x.base.ShopId === user.shopId)
            terminalGroups = terminalGroups.filter(x => x.base.ShopId === user.shopId)
          }
        }
        for (const group of userGroups) {
            group.relGroups = group.base.RelGroups.map((v) => {
                const g = terminalGroups.filter((t) => t.id == v );
                return g[0];
            })
            commit('addUserGroup', group);
        }
        for (const group of terminalGroups) {
            group.relGroups = group.base.RelGroups.map((v) => {
                const g = userGroups.filter((t) => t.id == v );
                return g[0];
            })
            commit('addTerminalGroup', group);
        }
    }
};


export default actions;
