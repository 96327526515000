



















import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

@Component
export default class Trouble extends Vue {
  @Action('terminalPlannedMaintSchedules/getTerminalPlannedMaintSchedules')
  getTerminalPlannedMaintSchedules!: (payload: any) => void;
  @Getter('terminalPlannedMaintSchedules/terminalPlannedMaintSchedules')
  terminalPlannedMaintSchedules!: any;
  @Getter('auth/user') user!: any;
  loading = true;
  searchStore: any = '';
  searchCompany: any = '';
  searchTerminal: any = '';
  search = '';
  searchId = '';
  headers = [
    { text: '日時', value: 'CreatedAt' },
    { text: '端末ID', value: 'TerminalId' },
    { text: '企業ID', value: 'CompanyId' },
    { text: '店舗ID', value: 'ShopId' },
    { text: '種別', value: 'TerminalKind' },
    { text: '予約ID', value: 'ScheduleId' },
  ];

  async created() {
    this.loading = true;
    this.searchCompany = this.$route.query.company
      ? this.$route.query.company
      : this.user.userCompany;

    this.searchStore = this.$route.query.store
      ? this.$route.query.store
      : undefined;

    this.searchTerminal = this.$route.query.terminal
      ? this.$route.query.terminal
      : undefined;

    const payload = {
      companyId: this.searchCompany,
      shopId: this.searchStore,
      terminalId: this.searchTerminal,
    };
    this.getTerminalPlannedMaintSchedules(payload);
    this.loading = false;
  }

  convertToLocaleString(time: number): string {
      if(time) {
          const dt = new Date(time);
          return `${dt.getFullYear()}年${("0" + (dt.getMonth() + 1)).slice(-2)}月${("0" + dt.getDate()).slice(-2)}日 ${dt.getHours()}:${dt.getMinutes()}`;
      } else
          return ""
      //   return moment.unix(time).utc().local().format('YYYY/MM/DD HH:mm:ss');
  }

}
