import CommAdminApi from '@/interfaces/commAdminApi';
import { CompanyEntity } from '../../models/internal/companyEntity.model';
import { User } from '@/models/commadmin/users.model';
import { AuthUser } from '@/models/internal/user.model';


export const account = {
  namespaced: true,
  state: { error: null, accounts: [] },
  mutations: {
    setAccounts(state: any, payload: AuthUser[]) {
      state.accounts = payload;
    },
  },
  actions: {
    async getAccounts({ commit, dispatch, rootGetters }: any, companyId?: string) {
      const companies: string[] = [];
      if (companyId) {
        companies.push(companyId);
      } else {
        const items: CompanyEntity[] = rootGetters['company/companies'];
        for (const item of items) {
          companies.push(item.base.Id);
        }
      }
      await dispatch('auth/authAction', null, {root: true})
      const user: AuthUser = rootGetters['auth/user'];
      // 何故かawaitが必要。
      const authKey = await rootGetters['auth/jwt'];
      const api = new CommAdminApi(authKey);
      let users: User[] = [];
      const promises: Promise<void | User[]>[] = [];
      for (const id of companies) {
        promises.push(api.getCompanyUsers(id).then((v) => {users = users.concat(v)}))
      }
      await Promise.all(promises);
      if (user.role === "ShopManager" || user.role === "ShopUser"){
        users = users.filter(x => x.ShopId === user.shopId)
      }
      commit('setAccounts', users);
    },
  },
  getters: {
    accounts: (state: any) => state.accounts,
  },
};
