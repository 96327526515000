import { Auth } from 'aws-amplify';
import { AuthAction, AuthState, UserAuth} from './type';
import { AuthUser } from '@/models/internal/user.model';


const state: AuthState = {
  isAuthenticated: false,
  token: null,
  user: null,
};

const makeUser = async () => {
  // const userInfo = await Auth.currentUserInfo();
  const authSession = await Auth.currentSession()
  const accessToken = authSession.getIdToken();
  const payload = accessToken.payload;
  const jwt = accessToken.getJwtToken();
  // TODO: [fkmy] refresh token考慮は必要？

  const user = {
    id: payload.sub as string || '',
    email: payload.email as string || '',
    groupId: payload.GroupId || '',
    companyId: payload.CompanyId || '',
    shopId: payload.ShopId || '',
    role: payload['cognito:groups'][0] || [],
  } as AuthUser;
  return { user, jwt };
};

export const getJwt = async () => {
  const authSession = await Auth.currentSession()
  const accessToken = authSession.getIdToken();
  const jwt = accessToken.getJwtToken();
  return jwt;
};

export const auth = {
  namespaced: true,
  state,
  mutations: {
    setLogout(state: AuthState) {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
    },
    setUser(state: AuthState, payload: AuthUser) {
      state.isAuthenticated = true;
      state.user = payload;
    },
    setToken(state: AuthState, payload: string) {
      state.token = payload;
    },
  },
  actions: {
    async logout({ commit }: AuthAction) {
      await commit('setLogout');
      return await Auth.signOut();
    },
    async login({ commit }: AuthAction, { email, password }: UserAuth) {
      try {
        console.log('auth');
        await Auth.signIn(email, password);
        console.log('auth ok');
        const { user, jwt } = await makeUser();
        console.log(user, jwt);
        commit('setUser', user);
        commit('setToken', jwt);

        return Promise.resolve('Success');
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    },
    // パ
    /*
    async confirmSignUp(_: any, { username, code }: UserConfirmSignup) {
      try {
        await Auth.confirmSignUp(username, code);
        return Promise.resolve('sucess');
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    },
    async signup(_: any, { username, password, email }: UserSignup) {
      try {
        await Auth.signUp({
          username,
          password,
          attributes: {
            email,
          },
        });
        return Promise.resolve('Sucess');
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    },
    */
    async authAction({ commit }: AuthAction) {
      const userInfo = await Auth.currentUserInfo();
      if (userInfo) {
        const { user, jwt } = await makeUser();
        commit('setUser', user);
        commit('setToken', jwt);
      } else {
        // ログアウトしている場合
        await commit('setLogout');
      }
      // let userInfo = await Auth.currentUserInfo();
      // await Auth.currentSession().then((res) => {
      //   const accessToken = res.getIdToken();
      //   //console.log('auth action', accessToken);
      //   const jwt = accessToken.getJwtToken();
      //   userInfo.jwtToken = jwt;
      //   userInfo.userCompany = accessToken.payload.CompanyId;
      // });
      // userInfo
      //   ? (userInfo.isAuthenticated = true)
      //   : (userInfo = { isAuthenticated: false });
    },
  },
  getters: {
    isAuthenticated:(state: AuthState) => state.isAuthenticated,
    user: (state: AuthState) => state.user,
    jwt: async (state: AuthState) => state.token,
    isSystemAdmin: (state: AuthState) => {
      if (!state.user) return false;
      return (state.user.role.indexOf('SystemAdmin') != -1);
    },
    /** システム管理者またはシステムユーザ */
    hasSystemPermission: (state: AuthState) => {
      if (!state.user) return false;
      return ['SystemAdmin', 'SystemUser'].includes(state.user.role);
    }
  },
};
