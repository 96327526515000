





































































































































































































































































































































































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any*/
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { TerminalEntity } from "@/models/internal/terminalEntity.model";
import SetupTerminal from "@/components/functionalities/SetupTerminal.vue";
import { CompanyEntity } from "../../models/internal/companyEntity.model";
import { ShopEntity } from "@/models/internal/shopEntity.model";
import { TerminalPropertyConfig } from "@/models/topup/terminalProperty.model";
import TopupAdminApi from "@/interfaces/topupAdminApi";
import { AuthUser } from "@/models/internal/user.model";
import { LoggingOperationHistoryParam } from "@/store/SystemOperationHistories/systemOperationHistories";
/* @ts-ignore */
import _ from "lodash";

const emptyToNull = (
  value: string | null | undefined,
  mode: "plane" | "s3arn" = "plane"
) => {
  if (!value) return null;
  if (value.length == 0) return null;
  if (mode == "s3arn") {
    // arn:aws:s3:::test-topup-brandingassets/Manjaro_logo_text.png
    if (value.indexOf("arn:aws:s3:::") != 0) return null;
  }
  return value;
};
// NOTE: デフォルトロゴは無しでOK
const emptyToDefault = (
  value: string | null | undefined,
  defaultValue: string,
  mode: "plane" | "s3arn" = "plane"
) => {
  return emptyToNull(value, mode) || defaultValue;
};

@Component({
  components: {
    SetupTerminal,
  },
})
export default class Device extends Vue {
  @Action("terminals/clearTerminals")
  clearTerminals!: () => void;
  @Action("terminals/getTerminals")
  getTerminals!: (payload: { companyId?: string; shopId?: string }) => void;
  @Action("terminals/updateTerminalProperties")
  updateTerminalProperties!: (payload: any) => void;
  @Action("terminals/createNewTerminalProperties")
  createNewTerminalProperties!: (payload: any) => void;
  @Getter("terminals/terminals") terminals!: TerminalEntity[];

  @Action("company/getCompany") getCompany!: (force?: boolean) => Promise<void>;
  @Getter("company/companies") companies!: CompanyEntity[];

  // @Action('shops/getShops') getShops!: (force?: boolean) => Promise<void>;
  @Getter("shops/shops") shops!: ShopEntity[];

  @Getter("auth/user") user!: AuthUser;
  @Getter("auth/hasSystemPermission") hasSystemPermission!: boolean; // TODO: 使われていないので、端末編集の権限が制御されていない恐れがある
  mobile = window.innerWidth > 768 ? false : true;
  @Action("systemOperationHistories/loggingOperationHistory")
  loggingOperationHistory!: (value: LoggingOperationHistoryParam) => void;

  mounted() {
    window.addEventListener("resize", () => {
      const sliderWidth = window.innerWidth;
      this.mobile = sliderWidth > 768 ? false : true;
    });
  }
  deviceEditFormValid = true;
  terminalPropsEdit = false;

  edit = {
    error: {
      status: false,
      message: "",
    },
  };
  editViewModel = {
    prop: {
      name: "",
    },
    location: {
      settingLocation: "",
      inquiryInfo: "",
    },
    sound: {},
    logo: {
      // TODO:  変更した場合のみ saveFileS3したい
      top: {
        position: "left",
        arn: "",
      },
      bottom: {
        position: "left",
        arn: "",
      },
      receiptImage: "",
    },
    design: {},
    timeout: {},
    schedule: {
      startTime: "10:00",
      endTime: "19:00",
      // 無人店舗設定
      hasSupportStaff: true,
      notInServiceArn: "",
    },
    // ポイント設定は Shiagel の設定を表示するのみ
    point: {
      moneyName: "電子マネー", // Shiagelの設定から
      moneyUnit: "円", // Shiagelの設定から
      pointName: "ポイント", // Shiagelの設定から
      pointUnit: "pt", // Shiagelの設定から
      secondPointName: "セカンドP", // Shiagelの設定から
      secondPointUnit: "P", // Shiagelの設定から
    },
    amountLimit: {
      dailyUpperLimit: 10000, // 店舗設定にあれば、初期値はコピーする
      totalUpperLimit: 50000, // Shiagelの設定から
    },
  };

  volumeBranding = 0;
  volumeAttention = 0;
  volumeInformation = 0;
  backgroundImageLabel: string | null = null;
  backgroundImageColor: string | null = null;
  topupButtonBackgroundColor: string | null = null;
  topupButtonShadowColor: string | null = null;
  topupButtonFontColor: string | null = null;
  topupButtonBorderColor: string | null = null;
  balanceButtonBackgroundColor: string | null = null;
  balanceButtonShadowColor: string | null = null;
  balanceButtonFontColor: string | null = null;
  balanceButtonBorderColor: string | null = null;
  timeout: number | null = null;
  scheduleAllDay: boolean | null = null;
  scheduleEndMode: string | null = null;
  isUsePoints: boolean | null = null;
  standByMode: string | null = null;
  standByImageLabel: string | null = null;
  currentItem: TerminalEntity | null = null;
  loading = false;

  search = {
    text: "",
    DisplayName: "",
    status: -1,
    disability: -1,
  };

  searchStatus = [
    {
      display: "",
      value: -1,
      installed: false,
      status: false,
    },
    {
      display: "未設置",
      value: 0,
      installed: false,
      status: false,
    },
    {
      display: "稼働中",
      value: 1,
      installed: true,
      status: true,
    },
    {
      display: "非稼働",
      value: 2,
      installed: true,
      status: false,
    },
  ];

  searchDisability = [
    {
      display: "",
      status: -1,
    },
    {
      display: "正常",
      status: 0,
    },
    {
      display: "障害中",
      status: 1,
    },
  ];

  @Watch("search", { immediate: true, deep: true })
  watchSearch() {
    console.log("watchSearch", JSON.stringify(this.search));
    console.log(this.terminals);
    console.log(this.companies);
  }

  panel = "sound";
  //jwtTokenにcompanyIdがない間に、直接にIdを入れる。Productionに移動する前に、消さなければならない。
  companyId?: string = this.$route.query.company
    ? this.$route.query.company.toString()
    : undefined;
  store?: string = this.$route.query.store
    ? this.$route.query.store.toString()
    : undefined;

  deviceEdit = false;
  deviceDelete = false;
  installDialog = false;
  deviceDetail = false;
  company = this.$route.query.company ? this.$route.query.company : null;

  headers = [
    {
      text: "ID",
      value: "base.Id",
    },
    { text: "表示名", value: "base.DisplayName" },
    { text: "企業", value: "company.Name", filter: this.terminalCompanyFilter },
    {
      text: "店舗",
      value: "shop.DisplayName",
      filter: this.searchDisplayNameFilter,
    },
    {
      text: "店舗ID",
      value: "shop.Id",
    },
    {
      text: "",
      value: "status",
      filter: this.searchStatusFilter,
    },
    {
      text: "",
      value: "actions",
      align: "right",
      sortable: false,
      filter: this.searchDisabilityFilter,
    },
  ];
  initialFileInput = [""];
  previousConfig = {} as TerminalPropertyConfig;

  // 店舗名 で フィルター
  searchDisplayNameFilter(value: any) {
    // console.log('searchDisplayNameFilter', value);
    if (
      typeof this.search.DisplayName == "undefined" ||
      this.search.DisplayName == ""
    ) {
      return true;
    }
    return value == this.search.DisplayName;
  }

  terminalCompanyFilter(value: any, search: any, item: any) {
    if (this.company) {
      return item.CompanyId === this.company;
    }
    return true;
  }

  clearImageField(type: string): void {
    console.log(type);
    if (type == "logo-top") {
      this.editViewModel.logo.top.arn = "";
    } else if (type == "logo-bottom") {
      this.editViewModel.logo.bottom.arn = "";
    } else if (type == "logo-receipt") {
      this.editViewModel.logo.receiptImage = "";
    } else if (type == "dashboard-background") {
      this.backgroundImageLabel = "";
    } else if (type == "schedule-notinservice") {
      this.editViewModel.schedule.notInServiceArn = "";
    }
  }

  searchDisabilityFilter(value: any, search: any, item: any): boolean {
    if (this.search.disability == -1) {
      return true;
    }
    if (
      item &&
      item.property &&
      (!item.property.Shiagel || !item.property.Shiagel.Installed)
    ) {
      return false;
    } else {
      if (!item.disability && this.search.disability == 0) return true;
      else if (item.disability && this.search.disability == 1) return true;
    }
    return false;
  }

  // 設置状態,障害情報 でフィルタリング
  searchStatusFilter(value: any, search: any, item: any): boolean {
    // console.log('searchStatusFilter', value, this.search.status, this.search.disability);
    if (this.search.status == -1) {
      return true;
    }

    if (
      item &&
      item.property &&
      (!item.property.Shiagel || !item.property.Shiagel.Installed) &&
      this.search.status == 0
    ) {
      // 未設置
      return true;
    } else {
      // TODO 設置済み で 稼働中,非稼働
      // item.properties.Shiagel.Installed && item.status
      if (
        item &&
        item.property &&
        item.property.Shiagel &&
        item.property.Shiagel.Installed &&
        this.search.status == 1 &&
        value
      )
        // 稼働中
        return true;
      else if (value == false && this.search.status == 2)
        // 非稼働
        return true;
    }
    console.log("return false");
    return false;
  }

  data: Array<any> = [];

  async created() {
    this.loadTerminals();
  }

  /** 端末リストを読み込む */
  async loadTerminals() {
    if (this.loading) {
      try {
        // ローディング待ち
        await new Promise<void>((resolve, reject) => {
          let count = 0;
          const timer = setInterval(() => {
            // 60秒以上待った場合、タイムアウトエラー
            if (60 * 2 < count) {
              clearInterval(timer);
              reject(new Error("timeout"));
            }

            if (!this.loading) {
              clearInterval(timer);
              resolve();
            }
            count++;
          }, 500);
        });
      } catch {
        // loading待ちがエラーだった場合は何もしない
        return;
      }
    }

    this.loading = true;
    this.clearTerminals();

    // let count = 0;
    // const timer = setInterval(() => {
    //   if (10 < count && this.terminals.length > 1) {
    //     clearInterval(timer);
    //     this.loading = false;
    //   }
    //   // 60秒以上待った場合、タイムアウトエラー
    //   if (60 < count) {
    //     clearInterval(timer);
    //     this.loading = false;
    //   }

    //   if (!this.loading) {
    //     clearInterval(timer);
    //   }
    //   count++;
    // }, 1000);

    let payload: any;
    if (this.companyId && this.store) {
      payload = {
        companyId: this.companyId,
        shopId: this.store,
        force: true,
      };
    } else {
      payload = {
        force: true,
      };
    }
    await this.getTerminals(payload);
    this.loading = false;
  }

  editItem(item: TerminalEntity) {
    if (!item) {
      throw new Error("invalid operation");
    }
    this.$nextTick(() => {
      this.currentItem = item;
      console.log("editItem", this.currentItem);

      if (!this.currentItem.property) throw new Error("invalid operation");

      this.editViewModel.prop.name =
        item.base.DisplayName || item.property?.DisplayName || item.base.Id;

      const company = this.companies.filter(
        (v) => v.base.Id == this.currentItem?.company?.Id
      )[0];
      const shop = this.shops.filter(
        (v) => v.base.Id == this.currentItem?.shop?.Id
      )[0];
      if (!shop || !company) {
        throw new Error("company/shop is not found");
      }

      // if (this.currentItem.property.Configs) {
      const config = this.currentItem.property.Configs;

      this.editViewModel.location.settingLocation = config?.Location || "";
      this.editViewModel.location.inquiryInfo =
        config?.InquiryInfo || shop.property?.InquiryInfo || "";

      // スケジュール設定
      this.editViewModel.schedule.startTime = emptyToDefault(
        config?.WorkSchedule.StartTime,
        "10:00"
      );
      this.editViewModel.schedule.endTime = emptyToDefault(
        config?.WorkSchedule.EndTime,
        "19:00"
      );
      this.editViewModel.schedule.hasSupportStaff =
        config?.HasSupportStaff || true;

      this.editViewModel.amountLimit.dailyUpperLimit =
        config?.UpperLimitAmount || company.property?.MaxTopupAmount || 0;

      //ターミナルプロパティ設定がないと、null値にする。
      this.volumeBranding = config?.Volumes.Branding || 50;
      this.volumeAttention = config?.Volumes.Attention || 50;
      this.volumeInformation = config?.Volumes.Information || 50;

      // ロゴ関連
      this.editViewModel.logo.top.arn = config?.BrandingLogo.Top.Arn || "";
      this.editViewModel.logo.top.position = emptyToDefault(
        config?.BrandingLogo.Top.Position,
        "left"
      );
      this.editViewModel.logo.bottom.arn =
        config?.BrandingLogo.Bottom.Arn || "";
      this.editViewModel.logo.bottom.position = emptyToDefault(
        config?.BrandingLogo.Bottom.Position,
        "left"
      );
      this.editViewModel.logo.receiptImage =
        config?.BrandingLogo.ReceiptImage || "";

      // ダッシュボード関連
      this.backgroundImageLabel =
        config?.Dashboard.Background.Image.Arn || null;

      this.standByImageLabel = config?.Standby.Signage.Slide.Arns[0] || null;
      this.timeout = config?.Timeout.DefaultTimeout || 30; // デフォルト30秒
      this.editViewModel.schedule.notInServiceArn =
        config?.WorkSchedule.NotInService.Arn || "";
      // }

      // Shiagelの設定から取得する項目
      // if (this.currentItem.property.Shiagel) {
      const shiagel = this.currentItem.property.Shiagel;
      this.editViewModel.point.moneyName =
        shiagel?.OperationalConfigs.Money?.Name || "";
      this.editViewModel.point.moneyUnit =
        shiagel?.OperationalConfigs.Money?.Unit || "";
      this.editViewModel.point.pointName =
        shiagel?.OperationalConfigs.Point?.Name || "";
      this.editViewModel.point.pointUnit =
        shiagel?.OperationalConfigs.Point?.Unit || "";
      this.editViewModel.point.secondPointName =
        shiagel?.OperationalConfigs.SecondPoint?.Name || "";
      this.editViewModel.point.secondPointUnit =
        shiagel?.OperationalConfigs.SecondPoint?.Unit || "";
      this.editViewModel.amountLimit.totalUpperLimit =
        shiagel?.OperationalConfigs.Money?.UpperLimitAmount || 0;
      // }

      this.scheduleAllDay = config?.WorkSchedule.All || false;
      this.scheduleEndMode = emptyToDefault(
        config?.WorkSchedule.EndMode,
        "sleep"
      );
      this.isUsePoints = config?.Point.Use || true;
      this.standByMode = emptyToDefault(config?.Standby.Mode, "sleep");

      // 色関連
      this.backgroundImageColor = emptyToDefault(
        config?.Dashboard.Background.Color,
        "#E2EAFD"
      );
      this.topupButtonBackgroundColor = emptyToDefault(
        config?.Dashboard.TopUpButton.BackgroundColor,
        "#F9FBFF"
      );
      this.topupButtonBorderColor = emptyToDefault(
        config?.Dashboard.TopUpButton.BorderColor,
        "#E6EAF1"
      );
      this.topupButtonShadowColor = emptyToDefault(
        config?.Dashboard.TopUpButton.ShadowColor,
        "#005AA0"
      );
      this.topupButtonFontColor = emptyToDefault(
        config?.Dashboard.TopUpButton.FontColor,
        "#46546D"
      );
      this.balanceButtonBackgroundColor = emptyToDefault(
        config?.Dashboard.BalanceButton.BackgroundColor,
        "#F9FBFF"
      );
      this.balanceButtonBorderColor = emptyToDefault(
        config?.Dashboard.BalanceButton.BorderColor,
        "#E6EAF1"
      );
      this.balanceButtonShadowColor = emptyToDefault(
        config?.Dashboard.BalanceButton.ShadowColor,
        "#005AA0"
      );
      this.balanceButtonFontColor = emptyToDefault(
        config?.Dashboard.BalanceButton.FontColor,
        "#46546D"
      );

      this.terminalPropsEdit = this.currentItem.property.Configs ? true : false;
    });

    this.panel = "sound";
    this.deviceEdit = true;
    this.edit.error.status = false;
    this.edit.error.message = "";

    const emptyToNull = (value: string, mode: "plane" | "s3arn" = "plane") => {
      if (value.length == 0) return null;
      if (mode == "s3arn") {
        // arn:aws:s3:::test-topup-brandingassets/Manjaro_logo_text.png
        if (value.indexOf("arn:aws:s3:::") != 0) return null;
      }
      return value;
    };
    this.previousConfig = {
      Volumes: {
        Branding: this.volumeBranding,
        Attention: this.volumeAttention,
        Information: this.volumeInformation,
      },
      BrandingLogo: {
        Top: {
          Position: this.editViewModel.logo.top.position,
          Arn: emptyToNull(this.editViewModel.logo.top.arn, "s3arn"),
        },
        Bottom: {
          Position: this.editViewModel.logo.bottom.position,
          Arn: emptyToNull(this.editViewModel.logo.bottom.arn, "s3arn"),
        },
        ReceiptImage: emptyToNull(
          this.editViewModel.logo.receiptImage,
          "s3arn"
        ),
      },
      Timeout: {
        DefaultTimeout: this.timeout,
      },
      UpperLimitAmount: this.editViewModel.amountLimit.dailyUpperLimit,
      WorkSchedule: {
        All: this.scheduleAllDay,
        StartTime: emptyToNull(this.editViewModel.schedule.startTime),
        EndTime: emptyToNull(this.editViewModel.schedule.endTime),
        EndMode: this.scheduleEndMode,
        NotInService: {
          Arn: emptyToNull(
            this.editViewModel.schedule.notInServiceArn,
            "s3arn"
          ),
        },
      },
      Point: {
        Use: this.isUsePoints,
      },
      Standby: {
        Mode: this.standByMode,
        Signage: {
          Slide: {
            Arns: [],
            Interval: 0, //need to fix
          },
        },
      },
      Dashboard: {
        Background: {
          Color: this.backgroundImageColor,
          Image: {
            Arn: this.backgroundImageLabel,
          },
        },
        TopUpButton: {
          BackgroundColor: this.topupButtonBackgroundColor,
          BorderColor: this.topupButtonBorderColor,
          ShadowColor: this.topupButtonShadowColor,
          FontColor: this.topupButtonFontColor,
        },
        BalanceButton: {
          BackgroundColor: this.balanceButtonBackgroundColor,
          BorderColor: this.balanceButtonBorderColor,
          ShadowColor: this.balanceButtonShadowColor,
          FontColor: this.balanceButtonFontColor,
        },
        Logo: {
          Arn: "",
        },
      },
      Background: {
        Color: this.backgroundImageColor,
        Image: {
          Arn: this.backgroundImageLabel,
        },
      },
      Location: this.editViewModel.location.settingLocation,
      InquiryInfo: this.editViewModel.location.inquiryInfo,
      HasSupportStaff: this.editViewModel.schedule.hasSupportStaff,
    };
  }

  close() {
    this.deviceEdit = false;
    this.edit.error.status = false;
    this.edit.error.message = "";
  }

  /** 端末詳細の編集結果を保存してダイアログを閉じる */

  async save() {
    try {
      // 入力値チェック
      const form = this.$refs.deviceEditForm as any;
      if (form.validate() == false) {
        console.log("save", "入力値に誤りがある");
        return;
      }

      if (!this.currentItem || !this.currentItem.property) {
        throw new Error("invalid operation");
      }

      // const defaultLogo = "arn:aws:s3:::test-topup-brandingassets/default_logo.bmp";

      const config: TerminalPropertyConfig = {
        Volumes: {
          Branding: Math.floor(this.volumeBranding),
          Attention: Math.floor(this.volumeAttention),
          Information: Math.floor(this.volumeInformation),
        },
        BrandingLogo: {
          Top: {
            Position: emptyToDefault(
              this.editViewModel.logo.top.position,
              "left"
            ),
            Arn: emptyToNull(this.editViewModel.logo.top.arn, "s3arn"),
          },
          Bottom: {
            Position: emptyToDefault(
              this.editViewModel.logo.bottom.position,
              "left"
            ),
            Arn: emptyToNull(this.editViewModel.logo.bottom.arn, "s3arn"),
          },
          ReceiptImage: emptyToNull(
            this.editViewModel.logo.receiptImage,
            "s3arn"
          ),
        },
        Timeout: {
          DefaultTimeout: this.timeout,
        },
        UpperLimitAmount: this.editViewModel.amountLimit.dailyUpperLimit,
        WorkSchedule: {
          All: this.scheduleAllDay,
          StartTime: emptyToDefault(
            this.editViewModel.schedule.startTime,
            "10:00"
          ),
          EndTime: emptyToDefault(this.editViewModel.schedule.endTime, "19:00"),
          EndMode: emptyToDefault(this.scheduleEndMode, "sleep"),
          NotInService: {
            Arn: emptyToNull(
              this.editViewModel.schedule.notInServiceArn,
              "s3arn"
            ),
          },
        },
        Point: {
          Use: this.isUsePoints || true,
        },
        Standby: {
          Mode: emptyToDefault(this.standByMode, "sleep"),
          Signage: {
            Slide: {
              Arns: [],
              Interval: 0, //need to fix
            },
          },
        },
        Dashboard: {
          Background: {
            Color: this.backgroundImageColor,
            Image: {
              Arn: emptyToDefault(this.backgroundImageLabel, "", "s3arn"), // NOT NULL
            },
          },
          TopUpButton: {
            BackgroundColor: this.topupButtonBackgroundColor,
            BorderColor: this.topupButtonBorderColor,
            ShadowColor: this.topupButtonShadowColor,
            FontColor: this.topupButtonFontColor,
          },
          BalanceButton: {
            BackgroundColor: this.balanceButtonBackgroundColor,
            BorderColor: this.balanceButtonBorderColor,
            ShadowColor: this.balanceButtonShadowColor,
            FontColor: this.balanceButtonFontColor,
          },
          Logo: {
            Arn: "",
          },
        },
        Background: {
          Color: this.backgroundImageColor,
          Image: {
            Arn: emptyToDefault(this.backgroundImageLabel, "", "s3arn"), // NOT NULL
          },
        },
        Location: this.editViewModel.location.settingLocation,
        InquiryInfo: this.editViewModel.location.inquiryInfo,
        HasSupportStaff: this.editViewModel.schedule.hasSupportStaff,
      };

      //ターミナルプロパティを更新
      const item = this.currentItem.property;
      item.Configs = config;
      if (!item.TroubleShootingUrl) {
        // トラブルシュート用のURL
        // TODO: 適切な設定から引っ張ってくるようにする
        // NOTE: 既存の端末がデータを持っていない場合があるので、ここで差し込む
        item.TroubleShootingUrl =
          "https://test-topup-assets.s3.ap-northeast-1.amazonaws.com/app/troubleshoot.html";
      }
      // console.log("this.previousConfig");
      // console.log(this.previousConfig);
      // console.log("this.currentConfig");
      // console.log(item.Configs);
      // const diff: any = {
      //   OldValues: _.pickBy(this.previousConfig, (value: any, key: any) => {
      //     /* @ts-ignore */
      //     return !_.isEqual(value, config[key]);
      //   }),
      //   NewValues: _.pickBy(config, (value: any, key: any) => {
      //     /* @ts-ignore */
      //     return !_.isEqual(this.previousConfig[key], value);
      //   }),
      // };

      if (!item.CreatedAt) item.CreatedAt = Date.now();
      await this.updateTerminalProperties(item);

      // 操作履歴
      await this.loggingOperationHistory({
        user: this.user,
        viewName: "端末設定編集画面",
        viewId: "EditTerminalProperty",
        operationName: "端末設定 保存",
        operationId: "editTerminalProperty",
        detail: { terminalId: this.currentItem.base.Id },
      });

      // this.loadTerminals();
      this.close();
    } catch (error) {
      console.error(error);
      this.edit.error.status = true;
      this.edit.error.message = "端末設定の保存に失敗しました。";
    }
  }

  async openDetail(item: TerminalEntity) {
    console.log("openDetail", item);
    this.currentItem = Object.assign({}, item);
    this.deviceDetail = true;
  }

  /** S3にファイルをアップロードし、対応するフィールドに保存する */
  async saveFileS3(name: string, file: any) {
    if (file === [] || file === [""]) return;
    try {
      this.deviceEditFormValid = false;
      console.log(`TYPE: ${typeof file} FILE`, file);
      if (file == null || file.size <= 0) {
        this.deviceEditFormValid = true;
        return;
      }
      // BASE64に変換
      const fileData = await (() => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result as string);
          reader.onerror = (error) => reject(error);
        });
      })();
      console.log(fileData);

      // S3にファイルをアップロード
      const content = fileData as string;
      const contentType = file.type as string;
      // if (!this.currentItem || !this.currentItem.property || !this.currentItem.property.CompanyId) {
      if (
        !this.currentItem ||
        !this.currentItem.property ||
        !this.currentItem.company?.Id
      ) {
        console.error("item is empty", this.currentItem);
        this.deviceEditFormValid = true;
        return;
      }
      console.log("Before this.editViewModel.logo.top.arn");
      console.log(this.editViewModel.logo.top.arn);

      const api = new TopupAdminApi();
      const { arn } = await api.uploadImage(
        // this.currentItem.property.CompanyId,
        this.currentItem.company?.Id,
        this.currentItem.base.Id,
        name,
        contentType,
        content
      );

      // arm の保存用値への設定 と ラベルに設定
      // if (
      //   !this.currentItem ||
      //   !this.currentItem.property ||
      //   !this.currentItem.property.Configs
      // )
      //   throw new Error("invalid operation");
      console.log("name");
      console.log(name);

      if ("logo-top" == name) {
        this.editViewModel.logo.top.arn = arn;
      } else if ("logo-bottom" == name) {
        this.editViewModel.logo.bottom.arn = arn;
      } else if ("logo-receipt" == name) {
        this.editViewModel.logo.receiptImage = arn;
      } else if ("dashboard-background" == name) {
        this.backgroundImageLabel = arn;
      } else if ("schedule-notinservice" == name) {
        this.editViewModel.schedule.notInServiceArn = arn;
      }
      // else if ('standByImage' == name) {
      //   this.standByImageLabel = arn;
      //   if (this.currentItem.property.Configs.Standby.Signage == null) {
      //     this.currentItem.property.Configs.Standby.Signage = {
      //       Slide: {
      //         Arns: [],
      //         Interval: 0,
      //       },
      //     }
      //   }
      //   console.log('Configs.Standby', this.currentItem?.property?.Configs?.Standby);
      //   this.currentItem.property.Configs.Standby.Signage.Slide.Arns[0] = arn;
      // }
      console.log("this.editViewModel");
      console.log(this.editViewModel);

      console.log(`position: ${name}, arn: ${arn}`);
    } catch (error) {
      console.error("Save file s3 error", error);
    } finally {
      this.deviceEditFormValid = true;
    }
  }

  /*
    async deleteFileS3(filename: string) {
      try {
        await Storage.remove(filename, { level: 'protected' });
      } catch (error) {
        console.error('remove s3 file error', error);
      }
    }
    */

  async openInstall(item: TerminalEntity) {
    console.log("openInstall", item);
    this.currentItem = item;
    this.installDialog = true;
  }

  // カラーコード バリデーション
  validationColorCode(value: string | null | undefined) {
    if (!value || value.length == 0) {
      return "カラーコードを入力してください";
    }
    if (value.length != 7) {
      return "カラーコード 7文字 を入力してください";
    }
    if (!value.match(/^#([A-Fa-f0-9]{6})$/)) {
      return "正しいカラーコードを入力してください";
    }
    return true;
  }
  validationTime(value: string | null | undefined) {
    console.log("validationTime", value);
    if (!value || value.length == 0 || value.length != 5) {
      return "時刻を入力してください";
    }
    if (!value.match(/^([0-9]{2}:[0-9]{2})$/)) {
      return "正しい時刻を入力してください";
    }
    return true;
  }
  validationNumber(value: string | null | undefined) {
    if (!value || value.length == 0) {
      return "数値を入力してください";
    }
    // if (!value.match(/^([0-9]+)$/)) {
    //   return "正しい数値を入力してください";
    // }
    return true;
  }
}
