import CommAdminApi from '@/interfaces/commAdminApi';
import { ShopEntity } from '@/models/internal/shopEntity.model';
import GraphQL from '@/interfaces/graphql';
import { getShopPropertiesById } from '@/graphql/queries';
import { getJwt } from '@/store/auth';
import { CompanyEntity } from '@/models/internal/companyEntity.model';
import { ShopProperty } from '@/models/topup/shopProperty.model';
import { AuthUser } from '@/models/internal/user.model';

interface ShopState {
  shops: ShopEntity[];
  subShops: ShopEntity[];
}

const state: ShopState = {
  shops: [],
  subShops: [],
}

export const shops = {
  namespaced: true,
  state,
  mutations: {
    setShops(state: ShopState, payload: ShopEntity[]) {
      state.shops = payload;
    },
    appendShops(state: ShopState, payload: ShopEntity[]) {
      for (const entity of payload) {
        state.shops.push(entity);
      }
    },
    setSubShops(state: ShopState, payload: ShopEntity[]) {
      state.subShops = payload;
    },
    appendSubShops(state: ShopState, payload: ShopEntity[]) {
      for (const entity of payload) {
        state.subShops.push(entity);
      }
    }
  },
  actions: {
    clearShops({ commit }: any) {
      commit('setShops', []);
    },
    /** 導入先企業に所属する導入先店舗を列挙する */
    async getShops({ commit, state, rootGetters }: any, companyId?: string, force = false) {
      if (!force && state.shops.length > 0) {
        return;
      }
      const authKey = await getJwt();
      const user: AuthUser = await rootGetters['auth/user'];
      const api = new CommAdminApi(authKey);
      const companies: string[] = [];
      if (companyId) {
        companies.push(companyId);
      } else {
        // 指定が無い場合は今のユーザが読み込める全ての企業を取得
        const items: CompanyEntity[] = rootGetters['company/companies'];
        for (const item of items) {
          companies.push(item.base.Id);
        }
      }
      const entities: ShopEntity[] = [];
      for (const id of companies) {
        // 共通管理からマスター取得
        const shops = await api.getStores(id);
        for (const shop of shops) {
          const params = { Id: shop.Id };
          const entity = { base: shop } as ShopEntity;
          try {
            // チャージ機管理用のプロパティ項目を取得
            const result = await GraphQL.query(getShopPropertiesById, params);
            const property = result.data.getShopPropertiesById as ShopProperty;
            // NOTE: なぜかデータによりフィールドがあったり無かったりするので、対策する
            if (!property.PhoneNo) property.PhoneNo = '';
            if (!property.InquiryInfo) property.InquiryInfo = '';
            if (!property.TaxOffice) property.TaxOffice = '';
            entity.property = property;
          } catch (ex) {
            // 取得できない場合も継続する
            // console.error('getShops', ex, shop);
            continue;
          }
          // console.log(entity);
          entities.push(entity);
        }
      }
      // console.log(entities);
      if (user.role === "ShopManager" || user.role === "ShopUser"){
        commit('setShops', entities.filter((x) => x.base.Id == user.shopId ));
      }else{
        commit('setShops', entities);
      }
      commit('setSubShops', entities);
    },
    // FIXME: [fkmy] もうちょい綺麗にコンパチにしたい
    async addShops({ commit }: any, companyId: string) {
      const authKey = await getJwt();
      const api = new CommAdminApi(authKey);
      const shops = await api.getStores(companyId);

      const entities: ShopEntity[] = [];
      for (const shop of shops) {
        const params = { Id: shop.Id };
        const result = await GraphQL.query(getShopPropertiesById, params);
        const property = result.data.getShopPropertiesById;
        entities.push({
          base: shop,
          property
        });
      }
      commit('appendShops', entities);
    },
    async updateShopProperties({ commit }: any, payload: any) {
      // console.log("updateShopProperties", payload);
      // TODO: [fkmy] updateShopProperties が無い
      // try {
      //   const shopProperties = await API.graphql(
      //     graphqlOperation(updateShopProperties, { input: payload })
      //   );
      // } catch (error) {
      //   throw new error('Error when updating shop properties', error);
      // }
    },
  },
  getters: {
    shops: (state: ShopState) => state.shops,
    subSops: (state: ShopState) => state.subShops,
  },
};
