import GraphQL from '@/interfaces/graphql';
import { listTerminalOperationHistories } from '@/graphql/queries';
import { 
  ListTerminalOperationHistoriesQuery,
  ListTerminalOperationHistoriesQueryVariables,
  TerminalOperationHistoriesFilterInput
} from '@/API'

const limit = 1000;
type NullableTerminalOperationHistories = NonNullable<NonNullable<ListTerminalOperationHistoriesQuery["listTerminalOperationHistories"]>["items"]>
type ListTerminalOperationHistories = NonNullable<NullableTerminalOperationHistories[number]>[]

export const getAllTerminalOperationHistories = async (filter: TerminalOperationHistoriesFilterInput | null, nextToken: string| null = null): Promise<ListTerminalOperationHistories> => {
  const queryVariable = {
    filter, limit, nextToken,
  } as ListTerminalOperationHistoriesQueryVariables
  const queryResult: {data: ListTerminalOperationHistoriesQuery} = await GraphQL.query(
    listTerminalOperationHistories,
    queryVariable,
    0
  ) as {data: ListTerminalOperationHistoriesQuery}
  const items = queryResult.data.listTerminalOperationHistories?.items
  const queryNextToken = queryResult.data.listTerminalOperationHistories?.nextToken
  if (!items) return []
  // エラークエリの場合はnullが混じるのでフィルタリングする。
  let result = items.filter((v) => v != null) as ListTerminalOperationHistories;
  if (queryNextToken){
    const nextResult = await getAllTerminalOperationHistories(filter, queryNextToken);
    result = result.concat(nextResult)
  }
  // console.log('[TerminalOperationHistories]', result);
  return result;
}
