import { ActionTree } from 'vuex';
import { RootState } from '@/store/type';
import { CompanyState } from './type';
import CommAdminApi from '@/interfaces/commAdminApi';
import { getJwt } from '@/store/auth';
import { CompanyEntity } from '@/models/internal/companyEntity.model';
import { AuthUser } from '@/models/internal/user.model';
import GraphQL from '@/interfaces/graphql';
import { getCompanyPropertiesById } from '@/graphql/queries';
import { CompanyProperty } from '@/models/topup/companyProperty.model';

const actions: ActionTree<CompanyState, RootState> = {
    async getCompany({ commit, state, rootGetters }, force = false) {
        if (!force && state.companies.length > 0) {
            return; // すでに読み込み済みの場合
        }
        const authKey = await getJwt();

        // const authKey = rootState.auth.token;
        const api = new CommAdminApi(authKey);
        const companies = await Promise.all((await api.getCompanies()).map(async (v) => {
            const entity: CompanyEntity = {
                base: v,
            };
            const params = { Id: v.Id };
            try {
                // チャージ機管理用のプロパティ項目を取得
                const result = await GraphQL.query(getCompanyPropertiesById, params);
                const property = result.data.getShopPropertiesById as CompanyProperty;
                entity.property = property;
            } catch (ex) {
                // 取得できない場合も継続する
                console.error('getCompany', ex, v);
                //   continue;
            }
            return entity;
        }));
        commit('setCompanies', companies);

        // 自分の所属企業の情報をセットする
        if (companies.length == 1) {
            commit('setMyCompany', companies[0])
        } else {
            const currentUser: AuthUser = rootGetters['auth/user'];
            const x = companies.filter((v) => v.base.Id == currentUser.companyId);
            if (x.length == 0) throw new Error('invalid value');
            // console.log("commit('setMyCompany', x[0])", x[0]);
            commit('setMyCompany', x[0])
        }
    },
    async fetchGroups({ commit, state, rootGetters }) {
        if (state.companies.length > 0) {
            throw new Error('invalid operation: before getCompany')
        }
        const companies: CompanyEntity[] = [];
        const authKey = await getJwt();
        const api = new CommAdminApi(authKey);
        for (const company of state.companies) {
            const groups = await api.getCompanyGroups(company.base.Id);
            const item = {
                ...company
            } as CompanyEntity;
            item.groups = groups
            companies.push(item);
        }
        commit('setCompanies', companies);

        // 自分の所属企業の情報をセットする
        if (companies.length == 1) {
            commit('setMyCompany', companies[0])
        } else {
            const currentUser: AuthUser = rootGetters['auth/user'];
            const x = companies.filter((v) => v.base.Id == currentUser.companyId);
            if (x.length == 0) throw new Error('invalid value');
            commit('setMyCompany', x[0])
        }
    }
};

export default actions;
