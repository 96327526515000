import { MutationTree } from 'vuex';
import { CompanyState } from './type';
import { CompanyEntity } from '@/models/internal/companyEntity.model';

const mutations: MutationTree<CompanyState> = {
    setCompanies(state: CompanyState, payload: CompanyEntity[]) {
        state.companies = payload;
    },
    setMyCompany(state: CompanyState, payload: CompanyEntity) {
        state.myCompany = payload;
    },
};

export default mutations;
