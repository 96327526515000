
































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { SetupTerminalPayload } from "@/store/Terminals/terminals";
import { TerminalEntity } from "../../models/internal/terminalEntity.model";
import { AuthUser } from '@/models/internal/user.model';
import { LoggingOperationHistoryParam } from '@/store/SystemOperationHistories/systemOperationHistories';

@Component
export default class SetupTerminal extends Vue {
  @Prop({ type: Object, required: true })
  terminal?: TerminalEntity;
  @Getter("auth/user") user!: AuthUser;
  @Action("systemOperationHistories/loggingOperationHistory")
  loggingOperationHistory!: (value: LoggingOperationHistoryParam) => void;
  @Action("terminals/setupTerminal") setupTerminal!: (
    payload: SetupTerminalPayload
  ) => Promise<void>;
  authCode: any = "";

  @Watch("terminal", { immediate: false, deep: true })
  onTerminalChange() {
    if (this.terminal?.property?.Shiagel?.AuthCode) {
      this.authCode = this.terminal.property.Shiagel.AuthCode;
    } else {
      this.authCode = "";
    }
    this.error.status = false;
    this.error.message = "";
  }
  // get authCode() {
  //   // 前回実行時の値
  //   if (this.terminal?.property?.Shiagel?.AuthCode) {
  //     return this.terminal.property.Shiagel.AuthCode;
  //   }
  //   return "";
  // }
  mounted() {
    if (this.terminal?.property?.Shiagel?.AuthCode) {
      this.authCode = this.terminal.property.Shiagel.AuthCode;
    } else {
      this.authCode = "";
    }
    this.error.status = false;
    this.error.message = "";
  }

  error = {
    status: false,
    message: "",
  };

  // バリデーション
  validation(value: string | null) {
    if (!value || value.length == 0) {
      // 入力されていない場合
      return "設置シリアル を入力してください";
    }
    if (8 < value.length) {
      // 8文字を超えている場合
      return "設置シリアル を入力してください";
    }
    return true;
  }

  async onSetupTerminalClieck() {
    if (!this.terminal) {
      // TODO: エラー処理
      console.error("invalid operation. terminal is null");
      return;
    }
    try {
      const payload = {
        terminalId: this.terminal.base.Id,
        authCode: this.authCode,
      } as SetupTerminalPayload;
      //ターミナルを設定

      await this.setupTerminal(payload);

      // 操作履歴
      await this.loggingOperationHistory({
        user: this.user,
        viewName: "端末設置画面",
        viewId: "SetupTerminal",
        operationName: "端末設置 実行",
        operationId: "setupTerminal",
        detail: { 
          terminalId: this.terminal.base.Id, 
          authCode: this.authCode 
        },
      });

      this.$emit("done");
    } catch (error) {
      console.error("Shiagel setup error", error);
      this.error.status = true;
      this.error.message = "端末設置に失敗しました。";
    }
  }

  async onCancelClick() {
    this.$emit("cancel");
  }
}
