























































































  import { Component, Vue, Watch } from 'vue-property-decorator';
  import { Action, Getter } from 'vuex-class';
  import { ShopEntity } from '@/models/internal/shopEntity.model';
  // import { ShopProperty } from '@/models/topup/shopProperty.model';
  import { CompanyEntity } from '@/models/internal/companyEntity.model';
  import { AuthUser } from '@/models/internal/user.model';
import moment from 'moment';

  type ListItem = {
    id: string;
    name: string;
    createdAt: string;
  };

  @Component
  export default class Store extends Vue {
    @Action('shops/getShops') getShops!: (companyId: string) => void;
    @Action('shops/addShops') addShops!: (companyId: string) => void;
    @Action('shops/clearShops') clearShops!: () => void;
    @Getter('shops/shops') shops!: ShopEntity[];
    @Getter('company/companies') companies!: CompanyEntity[];
    @Getter('auth/hasSystemPermission') hasSystemPermission!: boolean;

    @Action('shopProperties/updateShopProperties')
    updateShopProperties!: (payload: any) => void;

    @Getter('auth/user') user!: AuthUser;
    loading = true;
    storeSearch: any = this.$route.query.store ? this.$route.query.store : '';
    company = this.$route.query.company ? this.$route.query.company : '';
    selectedItemId: any = ''; // 選択されている店舗のID
    companyId = '';
    selectedStore = '';
    storeItems: any[] = [];
    /** 編集ダイアログの表示制御 */
    isEditDialogOpened = false;
    /** 詳細ダイアログの表示制御 */
    isDetailDialogOpend = false;

    shopDelete = false;
    storeSetting = false;

    headers = [
      { text: '店舗', value: 'base.DisplayName', filter: this.shopFilter,},
      { text: '作成日', value: 'createdAt' },
      { text: 'Id', value: 'id', align: ' d-none' },
      // { text: '', value: 'actions', align: 'right', sortable: false },
    ];
    headersAdmin = [
      { text: '企業', value: 'company.DisplayName' },
      { text: '店舗', value: 'base.DisplayName' },
      { text: '作成日', value: 'createdAt' },
      { text: 'Id', value: 'id', align: ' d-none' },
      // { text: '', value: 'actions', align: 'right', sortable: false },
    ];

    editedIndex = -1;
    editedItem: ShopEntity | null = null;
    defaultItem = {
      store: '',
      company: '',
    };

    selectedItem: ShopEntity | null = null;

    formTitle = this.editedIndex === -1 ? '新規店舗' : '店舗編集';

    // エラー発生時
    validation = {
      status: true,
      error: {
        message: '',
      }
    }

    listItems() {
      if(!this.shops) return [];
      if (this.hasSystemPermission) {
        return this.shops.filter((v) => {
            if(this.selectedStore && v.base.DisplayName == this.selectedStore && v.base.IsDelete == false)
                return true;
            else if(!this.selectedStore && v.base.IsDelete == false)
                return true;
            else
                return false;
        }).map((v) => {
          const item = {...v};
          for(const company of this.companies) {
            if(company.base.Id == v.base.CompanyId) {
              item.company = {
                Id: company.base.Id,
                Name: company.base.Name,
                DisplayName: company.base.DisplayName,
              }
            }
          }
          return item;
        });
      } else {
        return this.shops.filter((v) => {
            if(this.selectedStore && v.base.DisplayName == this.selectedStore && v.base.IsDelete == false)
                return true;
            else if(!this.selectedStore && v.base.IsDelete == false)
                return true;
            else
                return false;
        });
      }
    }

    async created() {
      this.loading = true;
      if (this.hasSystemPermission) {
        this.headers = this.headersAdmin;
      }
      this.companyId = this.$route.query.companyId
        ? this.$route.query.companyId.toString()
        : this.user.companyId;
      console.log(this.companyId);
      await this.loadShops(); // 初回ロード
      if (this.$route.query.store) {
        this.selectedItemId = await this.getShopName(this.storeSearch);
        this.selectedStore = this.selectedItemId;
      }
      this.storeItems = this.listItems();
      this.loading = false;
    }

    getShopName(shopId: string) {
      const result = this.shops.filter((shop) => shop.base.Id === shopId);
      if (result.length == 0) return;
      return result[0].base.DisplayName;
    }

    async loadShops() {
      // システム管理者 の場合は全ての企業の店舗を表示する
      if (this.hasSystemPermission && !this.$route.query.companyId) {
        this.clearShops();
        for(const company of this.companies) {
          await this.addShops(company.base.Id);
        }
      } else {
        this.clearShops();
        await this.getShops(this.companyId);
      }
    }

    shopFilter(value: any): boolean {
        console.log("u enter", this.selectedStore)
        if(!this.selectedStore) {
            return true;
        } else if(value == this.selectedStore){
            return true;
        }
        return false;
    }

    convertToLocaleString(time: number): string {
        if(time) {
            const dt = new Date(moment.unix(time).utc().local().format('YYYY/MM/DD HH:mm:ss'));
            return `${dt.getFullYear()}年${("0" + (dt.getMonth() + 1)).slice(-2)}月${("0" + dt.getDate()).slice(-2)}日`;
        } else
            return ""
    }

    @Watch('selectedStore', {immediate: true, deep: true})
    watchSearch() {
      console.log('watchSearch', JSON.stringify(this.selectedStore));
      this.loading = false;
      this.storeItems = this.listItems();
      this.loading = false;
    }

    @Watch('shopEdit', { deep: true })
    async onShopEdit(val: any) {
      val || this.closeEditDialog();
    }
    @Watch('shopDelete', { deep: true })
    async onShopDelete(val: any) {
      val || this.closeDelete();
    }

    get formtitle() {
      return this.formTitle;
    }
    set formtitle(newFormTitle) {
      this.formTitle = newFormTitle;
    }

    /** 編集ダイアログを開く */
    async editItem(item: ShopEntity) {
      console.log("editItem", item);
      this.validation.status = true;
      // this.editedIndex = this.shops.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.isEditDialogOpened = true;

      console.log("editItem", this.editedIndex, this.editedItem);
    }

    deleteItem(item: any) {
      this.editedIndex = this.shops.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.shopDelete = true;
    }

    deleteItemConfirm() {
      this.shops.splice(this.editedIndex, 1);
      this.closeDelete();
    }

    /** 編集ダイアログを閉じる */
    closeEditDialog() {
      this.isEditDialogOpened = false;
      this.$nextTick(() => {
        // this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem = null;
        this.editedIndex = -1;
      });
    }

    closeDelete() {
      this.shopDelete = false;
      this.$nextTick(() => {
        // this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem = null;
        this.editedIndex = -1;
      });
    }

    // 文字数カウント
    stringCount(str: string) {
      let len = 0;
      for (let i = 0; i < str.length; i++) {
        (str[i].match(/[ -~]/)) ? len += 1 : len += 2;
      }
      return len;
    }

    // 入力値チェックをする
    shopEditValidation() {
      this.validation.status = true;
      console.log("shopEditValidation TaxOffice", this.editedItem);
      if (this.editedItem && this.editedItem.property && this.editedItem.property.TaxOffice) {
        const len = this.stringCount(this.editedItem.property.TaxOffice);
        console.log("shopEditValidation TaxOffice len", len);
      }
      /*
      // TODO 税務署名(全角5文字まで) this.editedItem.TaxOffice
      if (len > 5) {
        this.validation.error.message = "税務署名(全角5文字まで) です。";
        this.validation.status = false;
      }
      */
      // 営業時間設定に誤りがあります。有効な時間を入力してください。

      return this.validation.status;
    }

    async save() {
      // 入力値チェックをする
      if(this.shopEditValidation() == false) {
        // 入力値に誤りがあった
        return;
      }

      // if (this.editedIndex > -1) {
      //   Object.assign(this.shops[this.editedIndex], this.editedItem);
      // } else {
      //   this.shops.push(this.editedItem);
      // }
      // DB更新
      await this.updateShopProperties(this.editedItem?.property);
      // データ再取得
      await this.loadShops();

      this.closeEditDialog();
    }

    /** 行をクリックして詳細ダイアログを表示 */
    async openDetail(item: ShopEntity) {
      this.selectedItem = item;
      this.isDetailDialogOpend = true;
    }

    async filterStore(value: any, search: any) {
      return (
        value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().indexOf(search) !== -1
      );
    }
  }
