import { getAllTerminalMalfunctionHistories } from '@/interfaces/graphql/terminalMalfunctionHistories';
import * as _ from 'lodash';

export const terminalMalfunctionHistories = {
  namespaced: true,
  state: { error: null, terminalMalfunctionHistories: [] },
  mutations: {
    setTerminalMalfunctionHistories(state: any, payload: any) {
      state.terminalMalfunctionHistories = payload;
    },
  },
  actions: {
    async getTerminalMalfunctionHistories(
      { commit, dispatch, rootState, rootGetters }: any,
      dataParams: any
    ) {

      let filter = null;
      if (!dataParams?.terminalId) {
        /*
        const authSession = await Auth.currentSession();
        const accessToken = authSession.getIdToken();
        companyId = accessToken.payload.CompanyId;
        filter = {
          CompanyId: {
            eq: companyId,
          },
        };
        */
        filter = {
          Status: {
            eq: "open",
          },
        }
        //throw new Error('Missing parameter: TerminalId');
        console.error('Missing parameter: TerminalIds');
      } else {
        filter = {
          TerminalId: {
            eq: dataParams.terminalId ? dataParams.terminalId : null,
          },
          TimestampMs: {
            eq: dataParams.timestampMs ? parseInt(dataParams.timestampMs) : null
          },
          Status: {
            eq: "open"
          }
        };
      }
      try {
        if (rootState.terminals.terminals.length == 0) {
          await dispatch('terminals/getTerminals', { force: true }, { root: true });
        }
        const result = await getAllTerminalMalfunctionHistories(filter)
        const terminalIds: string[] = await rootGetters['terminals/terminalIds'];
        const res = result.filter(x => terminalIds.includes(x.TerminalId));
        const sortedObj = _.orderBy(res, (a) => new Date(a.TimestampMs), ['desc']);
        commit(
          'setTerminalMalfunctionHistories', sortedObj
        );
      } catch (error) {
        console.error('Get Terminal Malfunction Histories error', error);
      }
    },
  },
  getters: {
    terminalMalfunctionHistories: (state: any) =>
      state.terminalMalfunctionHistories,
  },
};
