






































































































import Logo from "../Logo.vue";
import { Action, Getter } from "vuex-class";
import { Component, Vue, Watch } from "vue-property-decorator";
import { CompanyEntity } from "@/models/internal/companyEntity.model";
import { AuthUser } from "@/models/internal/user.model";

@Component({
  components: {
    Logo,
  },
})
export default class Navbar extends Vue {
  @Action("auth/logout")
  logout!: () => void;
  @Getter("auth/user") user!: AuthUser | null;

  @Getter("company/myCompany") myCompany!: CompanyEntity | null;
  @Getter("company/companies") companies!: CompanyEntity[];

  drawer = false;
  mobile = window.innerWidth > 768 ? false : true;

  mounted() {
    window.addEventListener("resize", () => {
      const sliderWidth = window.innerWidth;
      this.mobile = sliderWidth > 768 ? false : true;
    });
  }

  username = "";
  fixed = false;
  items = [
    {
      icon: "mdi-chart-pie",
      title: "ダッシュボード",
      to: "/",
    },
    {
      icon: "mdi-account",
      title: "アカウント",
      to: "/account",
    },
    {
      icon: "mdi-office-building-outline",
      title: "企業",
      to: "/company",
    },
    {
      icon: "mdi-store",
      title: "店舗",
      to: "/store",
    },
    {
      icon: "mdi-account-group",
      title: "グループ",
      active: false,
      childPages: [
        {
          title: "ユーザー",
          to: "/group/user",
        },
        {
          title: "ターミナル",
          to: "/group/device",
        },
      ],
    },
    {
      icon: "mdi-cellphone-cog",
      title: "チャージ機",
      childPages: [
        {
          title: "一覧",
          to: "/device",
        },
        // {
        //   title: '障害管理',
        //   to: '/device/trouble',
        // },
      ],
    },
    {
      icon: "mdi-history",
      title: "取引履歴",
      to: "/logs/payment",
    },
    {
      icon: "mdi-cog-outline",
      title: "管理",
      active: false,
      childPages: [
        {
          title: "システム操作履歴",
          to: "/logs/operation",
        },
        // {
        //   title: '端末ログ',
        //   to: '/logs/device',
        // },
        {
          title: "端末ログ",
          to: "/logs/device-operation",
        },
        {
          title: "障害管理",
          // to: '/management/trouble',
          to: "/device/trouble",
        },
        {
          title: "使用制限",
          to: "/management/functionRestriction",
        },
      ],
    },
  ];
  companyName = "";
  isInRestriction = false;
  isSystemAdmin = false;

  // get companyName() {
  //   if (!this.myCompany) return "Loading...";
  //   return this.myCompany.base.DisplayName;
  // }

  // set companyName(value) {
  //   this.companyName = value || "Loading";
  // }

  get userInitial() {
    if (!this.user) return "  ";
    return "UN"; // FIXME: [fkmy] ユーザ名があるならイニシャルを生成
  }

  get userEmail() {
    if (!this.user) return "...";
    return this.user.email;
  }

  @Watch("user", { immediate: true, deep: true })
  async onUserChange() {
    this.companyName = "Loading...";
    await this.$store.dispatch("company/getCompany", true, { root: true });
    this.companyName =
      this.$store.state.company.myCompany?.base.DisplayName || "Loading...";
    let mainteSchedules = [];
    await this.$store
      .dispatch(
        "systemPlannedMaintenanceSchedules/getSystemPlannedMaintenanceSchedules",
        null
      )
      .then(() => {
        mainteSchedules =
          this.$store.getters[
            "systemPlannedMaintenanceSchedules/systemPlannedMaintenanceSchedules"
          ];
        if (mainteSchedules[0].EndTimestampMs) {
          if (
            mainteSchedules[0]?.StartTimestampMs < Date.now() &&
            mainteSchedules[0]?.EndTimestampMs > Date.now()
          ) {
            this.isInRestriction = true;
          } else {
            this.isInRestriction = false;
          }
        } else {
          this.isInRestriction = false;
        }
      });

    // TODO: これはどこで使われている？使われていないなら不要
    this.isSystemAdmin = this.user?.role === "SystemAdmin";
    console.log("isSystemAdmin", this.isSystemAdmin);
  }

  async logoutUser() {
    try {
      await this.logout();
      this.$router.push("/login");
    } catch (error) {
      console.error("logout error", error);
    }
  }
}
