/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:2fe61b3d-ac1f-453f-9efa-d3a4990499fb",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_LdjQWjKoa",
    "aws_user_pools_web_client_id": "6142u2mm7dajvgvrpbnbteefb5",
    "oauth": {},
    "aws_content_delivery_bucket": "topupweb-20210904143855-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://d1utxe3453nkfh.cloudfront.net",
    "aws_user_files_s3_bucket": "topupwebda2dd5667b104c6ab189445c8f11d49d152103-prod",
    "aws_user_files_s3_bucket_region": "ap-northeast-1",
    "aws_appsync_graphqlEndpoint": "https://bhnhokilyfdbrp53fztc2apm2e.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-wpdah6zb3zbrtctmp4z4wpoyfe"
};


export default awsmobile;
