import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './type';
import { auth } from './auth';
import { systemMalfunctionHistories as systemMalfunctions } from './SystemMalfunctionHistories/systemMalFunctionHistories';
import { terminalErrorLogs } from './TerminalErrorLogs/terminalErrorLogs';
import { transactions } from './Transactions/transactions';
import { company } from './Company';
import { account } from './Account/account';
import { shops } from './Shops';
import { users } from './Users/users';
import { terminalMalfunctionHistories } from './TerminalMalfunctionHistories/terminalMalfunctionHistories';
import { terminalOperationHistories } from './TerminalOperationHistories/terminalOperationHistories';
import { systemOperationHistories } from './SystemOperationHistories/systemOperationHistories';
import { terminals } from './Terminals/terminals';
import { systemPlannedMaintenanceSchedules } from './SystemPlannedMaintenanceSchedules/systemPlannedMaintenanceSchedules';
import { terminalPlannedMaintSchedules } from './TerminalPlannedMaintSchedules/terminalPlannedMaintSchedules';
import { group } from './group';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state: {
    version: '1.0.0',
    isLoading: false,
  },
  modules: {
    auth,
    systemMalfunctions,
    systemPlannedMaintenanceSchedules,
    terminalErrorLogs,
    transactions,
    company,
    account,
    shops,
    users,
    terminalMalfunctionHistories,
    terminalOperationHistories,
    systemOperationHistories,
    terminals,
    terminalPlannedMaintSchedules,
    group,
  },
};

export default new Vuex.Store<RootState>(store);
