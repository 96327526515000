import { getAllTerminalOperationHistories } from '@/interfaces/graphql/terminalOperationHistories'

export const terminalOperationHistories = {
  namespaced: true,
  state: { error: null, terminalOperationHistories: [] },
  mutations: {
    setTerminalOperationHistories(state: any, payload: any) {
      state.terminalOperationHistories = payload;
    },
  },
  actions: {
    async getTerminalOperationHistories({ commit, dispatch, rootState, rootGetters }: any) {
      if (rootState.terminals.terminals.length == 0) {
        await dispatch('terminals/getTerminals', { force: true}, { root: true });
      }
      const result = await getAllTerminalOperationHistories(null);
      const terminalIds = await rootGetters['terminals/terminalIds'];
      commit('setTerminalOperationHistories', result.filter(x => terminalIds.includes(x.TerminalId)));
    },
  },
  getters: {
    terminalOperationHistories: (state: any) => state.terminalOperationHistories
  },
};
