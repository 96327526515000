





































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { CompanyEntity } from '@/models/internal/companyEntity.model';
import {
    // UserGroupEntity,
    TerminalGroupEntity,
    // UserItem,
} from '@/models/internal/groupEntity.model';
import { TerminalEntity } from '../../../models/internal/terminalEntity.model';
// import { CodeStarNotifications } from 'aws-sdk';
import { ShopEntity } from '../../../models/internal/shopEntity.model';

@Component
export default class Device extends Vue {
  @Action('group/fetchGroups') fetchGroups!: () => Promise<void>;
  @Getter('group/terminalGroups') terminalGroups!: TerminalGroupEntity[];

  companyId = '';
//   @Action('company/getCompany') getCompany!: (force?: boolean) => Promise<void>;
//   @Getter('company/companies') companies!: CompanyEntity[];

  @Action('shops/getShops') getShops!: (
    companyId: string,
  ) => void;
  @Getter('shops/shops') shops!: ShopEntity[];

  @Action('users/getUsers') getUsers!: (
    companyId: string,
    token: string
  ) => void;
  @Getter('users/users') users!: any;

//   @Action('terminals/getTerminals')
//   getTerminals!: (payload: {
//     companyId?: string;
//     shopId?: string;
//     token?: string;
//   }) => void;
//   @Getter('terminals/terminals') terminals!: TerminalEntity[];

  @Getter('auth/user') user!: any;

  groupHeaders = [
    { text: 'ターミナルグループ', value: 'name', sortable: false },
    { text: '', value: 'actions', align: 'right', sortable: false },
  ];
  terminalHeaders = [
    { text: 'ID', value: 'id', sortable: false },
    { text: '所属', value: 'affiliation', sortable: false },
  ];
  accountHeaders = [
    { text: 'ユーザーグループ', value: 'name', sortable: false },
  ];
  groupData: Array<any> = [
    /*
    {
      name: 'ターミナルグループ01',
      id: 'dg0000000000000001',
      userGroupList: [
        {
          name: 'ユーザーグループ01',
          id: 'ug0000000000000001',
        },
      ],
    },
    */
  ];

  accountData: Array<any> = [
    /*
    {
      name: 'ユーザーグループ01',
      id: 'ug0000000000000001',
    },
    */
  ];

  terminalData: Array<any> = [
    /*

    */
  ];
  loading = true;
  store: any = '';
  currentGroup: any | null = null;
  selectedAccountGroup = [];
  selectedTerminalGroup = [];
  selected: Array<any> = [];
  search = this.$route.query.group ? this.$route.query.group : '';
  groupEdit = false;
  groupDelete = false;
  groupDetail = false;
  editedItem = {
    name: '',
    id: '',
    groupList: [],
  };
  defaultItem = {
    name: '',
    id: '',
    groupList: [],
  };

  async created() {
    this.loading=true;
    //this.initialize();
    console.log("user", this.user);

    this.companyId = this.$route.query.company
      ? this.$route.query.company.toString()
      : this.user.userCompany;
    console.log("companyId", this.companyId);

    const token = this.user.jwtToken;
    console.log("token", token);

    // 企業一覧を取得

    // ログインユーザーの所属 店舗一覧を取得
    await this.getShops(this.companyId);
    console.log("stores-->", JSON.stringify(this.shops));

    // ログインしているユーザーが所属している 企業の ユーザー一覧を取得
    await this.getUsers(this.companyId, token);
    console.log("users-->", JSON.stringify(this.users));

    this.store = this.$route.query.store
      ? this.$route.query.store.toString()
      : undefined;
    const payload = {
      companyId: this.companyId,
      shopId: this.store,
      token,
    };
    await this.fetchGroups();

    this.initialize();
    this.loading=false;
  }

  @Watch('groupEdit', { deep: true })
  async ongroupEdit(val: any) {
    val || this.close();
  }
  @Watch('groupDetail', { deep: true })
  async ongroupDetail(val: any) {
    val || this.closeDelete();
  }

  initialize() {
    this.groupData = [];
    let tGroupItems: any[] = this.terminalGroups;

    if(this.companyId)
        tGroupItems = tGroupItems.filter(t=> t.base.CompanyId == this.companyId);
    if(this.store)
        tGroupItems = tGroupItems.filter(t=> t.base.ShopId == this.store);

    for(const t of tGroupItems) {
        const group: any = {
            name: t.base.Name,
            id: t.base.Id,
            userGroupList: []
        };
        for (const g of t.relGroups) {
            if(g != undefined)
                group.userGroupList.push({ name: g.name, id: g.id});
        }
        this.accountData = [];
        this.users.forEach((user: any) => {
            const filterStore = this.shops.filter((s: ShopEntity) => (s.base.Id == user.ShopId));
            console.log('store', user.ShopId, filterStore);
            const storeName = (filterStore && filterStore.length == 1) ? filterStore[0].base.DisplayName : '';

            const account = {
                id: user.Id,
                company: t.base.CompanyId,
                store: storeName,
                roll: user.CognitoGroup,
                lastLogin: user.UpdatedAt,
                isValid: user.IsDelete,
                maintenanceCardId: user.MaintenanceCardId,
                userGroup: {
                id: user.CompanyId,
                name: `${user.GroupId}`,
                },
            };
            this.accountData.push( account );
        });
        this.groupData.push(group);
        console.log('accountData', this.accountData);
    }
    this.terminalData = [];
  }

  groupRowClasses(item: any) {
    if (this.currentGroup && item.id === this.currentGroup.id) {
      return 'active';
    }
  }

  toggleSelected(item: any) {
    if (this.selected.includes(item)) {
      this.selected = this.selected.filter((v) => v !== item);
    } else {
      this.selected.push(item);
    }
  }

  editItem() {
    this.editedItem = Object.assign({}, this.currentGroup);
    this.groupEdit = true;
  }

  close() {
    this.groupEdit = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
    });
  }

  closeDelete() {
    this.groupDelete = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
    });
  }

  save() {
    this.close();
  }

  openDetail(item: any) {
    this.currentGroup = item;
    this.terminalData = [item];
    /*
    this.selected = this.terminals.filter(
      (terminal: any) => terminal.GroupId === this.currentGroup.GroupId
    );
    */
  }
}
