















import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

@Component
export default class LogsDeviceOperation extends Vue {
  search = '';
  searchId = '';
  headers = [
    { text: '発生日時', value: 'CreatedAt' },
    { text: '端末ID', value: 'TerminalId' },
    { text: '画面', value: 'ViewName' },
    { text: '操作', value: 'OperationName' },
    { text: '詳細データ', value: 'Detail' },
  ];

  @Action('terminalOperationHistories/getTerminalOperationHistories')
  getTerminalOperationHistories!: () => any;

  @Getter('terminalOperationHistories/terminalOperationHistories')
  terminalOperationHistories!: any;

  created() {
    this.getTerminalOperationHistories();
  }
  convertToLocaleString(value: number): string {
    return new Date(
      value * 1000
    ).toLocaleString('ja-JP');
  }
}
