










































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { AuthUser } from '@/models/internal/user.model';
import {
    UserGroupEntity,
    TerminalGroupEntity,
    UserItem,
} from '@/models/internal/groupEntity.model';

@Component
export default class Group extends Vue {
  @Action('group/fetchGroups') fetchGroups!: () => Promise<void>;
  @Getter('group/userGroups') userGroups!: UserGroupEntity[];

  @Getter('auth/user') user!: AuthUser;

  groupHeaders = [
    { text: 'ユーザーグループ', value: 'name', sortable: false },
    { text: '', value: 'actions', align: 'right', sortable: false },
  ];
  accountHeaders = [
    { text: 'ID', value: 'id', sortable: false },
    { text: 'ロール', value: 'roleTitle', sortable: false },
    { text: '所属', value: 'shopName', sortable: false },
  ];
  terminalHeaders = [
    { text: 'ターミナルグループ', value: 'name', sortable: false },
  ];
  groupData: UserGroupEntity[] = [];
  currentGroup: any = null;
  currentGroupUsers: any[] = [];
  loading = true;
  selectedAccountGroup: UserItem[] = [];
  selectedTerminalGroup: TerminalGroupEntity[] = [];
  selected: Array<any> = [];
  search = this.$route.query.group ? this.$route.query.group : '';
  groupEdit = false;
  groupDelete = false;
  groupDetail = false;
  editedItem: UserGroupEntity | null = null;

  companyId = '';
  rollItems = [
        {
        label: '店舗スタッフ',
        value: 'ShopUser',
        },
        {
        label: '店舗管理者',
        value: 'ShopManager',
        },
        {
        label: 'スーパーバイザー',
        value: 'CorpSuperVisor',
        },
        {
        label: '企業管理者',
        value: 'CorpAdmin',
        },
        {
        label: 'システムユーザ',
        value: 'SystemUser',
        },
        {
        label: 'システム管理者',
        value: 'SystemAdmin',
        },
  ];

  async created() {
    this.loading = true;
    // this.companyId = this.$route.query.companyId
    //   ? this.$route.query.companyId.toString()
    //   : this.user?.companyId;
    await this.initialize();
    this.loading = false;
  }

  async initialize() {
    try {
      // データロード
      await this.fetchGroups();
    } catch(ex) {
      console.error(ex);
    }

    this.groupData = [];
    let tGroupItems: any[] = this.userGroups;

    if(this.companyId)
        tGroupItems = tGroupItems.filter(t=> t.base.CompanyId == this.companyId);
    if(this.search)
        tGroupItems = tGroupItems.filter(t=> t.name == this.search);

    for(const t of tGroupItems) {
        const group: any = t;
        group["deviceGroupList"] = [];
        for (const g of t.relGroups) {
            if(g != undefined)
                group.deviceGroupList.push({ name: g.name, id: g.id});
        }
        this.groupData.push(group);
    }

    // this.users.forEach((user: any) => {

    //   // ユーザーにしか GroupId がないのでここでリストに追加する
    //   group.deviceGroupList = [];
    //   group.deviceGroupList.push({ name: `${user.GroupId}`, id: `${user.id}_${user.GroupId}`});

    //   const filterStore = this.stores.filter((s: any) => (s.id == user.ShopId));
    //   console.log('store', user.ShopId, filterStore);
    //   const storeName = (filterStore && filterStore.length == 1) ? filterStore[0].Name : '';

    //   const account = {
    //     id: user.id,
    //     company: this.companies[0].base.Name, // FIXME: 然るべきものに
    //     store: storeName,
    //     role: user.CognitoGroup,
    //     lastLogin: user.LastLoginDate,
    //     isValid: user.isDelete,
    //     maintenanceCardId: user.maintenanceCardId,
    //     userGroup: {
    //       id: user.CompanyId,
    //       name: `${user.GroupId}`,
    //     },
    //   };
    //   this.accountData.push( account );
    // });
    // console.log('accountData', this.accountData);

  }

  @Watch('groupEdit', { deep: true })
  async ongroupEdit(val: any) {
    val || this.close();
  }
  @Watch('groupDelete', { deep: true })
  async ongroupDelete(val: any) {
    val || this.closeDelete();
  }


    getRollLabel(role: string) {
        for (const item of this.rollItems) {
            if (item.value == role) {
                return item.label
            }
        }
        return '';
    }

  groupRowClasses(item: any) {
    if (this.currentGroup && item.id === this.currentGroup.id) {
      return 'active';
    }
  }

  toggleSelected(item: any) {
    console.log("toggleSelected", item);
    if (this.selected.includes(item)) {
      this.selected = this.selected.filter((v) => v !== item);
    } else {
      this.selected.push(item);
    }
  }

  editItem() {
    this.editedItem = Object.assign({}, this.currentGroup);
    this.groupEdit = true;
  }

  close() {
    this.groupEdit = false;
    this.$nextTick(() => {
      this.editedItem = null;
    });
  }

  closeDelete() {
    this.groupDelete = false;
    this.$nextTick(() => {
      this.editedItem = null;
    });
  }

  save() {
    this.close();
  }

  openDetail(item: any) {
    this.currentGroup = item;
    this.currentGroupUsers = item.users;
    console.log("openDetail", JSON.stringify(this.currentGroupUsers));
    this.selected = this.userGroups.filter(
      (user) => user.id === this.currentGroup.id
    );
  }
}
