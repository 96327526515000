

























































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { ShopEntity } from "@/models/internal/shopEntity.model";
import moment from "moment";
import { AuthUser } from "../../models/internal/user.model";
import { UserGroupEntity } from "../../models/internal/groupEntity.model";
import { CompanyEntity } from "../../models/internal/companyEntity.model";

@Component
export default class Account extends Vue {
  @Action("account/getAccounts") getAccounts!: (companyId: string) => void;
  @Action("shops/getShops") getShops!: (companyId: string) => void;
  @Action("shops/addShops") addShops!: (companyId: string) => void;
  @Action("shops/clearShops") clearShops!: () => void;
  @Getter("shops/shops") shops!: any[];

  @Getter("account/accounts") accounts!: any[];
  @Getter("auth/user") user!: AuthUser;
  @Action("group/fetchGroups") fetchGroups!: () => Promise<void>;
  @Getter("group/userGroups") userGroups!: UserGroupEntity[];
  @Getter("auth/hasSystemPermission") hasSystemPermission!: boolean;
  @Getter("company/companies") companies!: CompanyEntity[];

  search = "";

  //jwtTokenにcompanyIdがない間に、直接にIdを入れる。Productionに移動する前に、消さなければならない。
  company: any = "";
  userAccounts: any = [];
  store = this.$route.query.store ? this.$route.query.store : "";
  accountDetailInfo: any = {};
  companyItems: Array<any> = [
    "ABC1株式会社",
    "ABC2株式会社",
    "ABC3株式会社",
    "ABC4株式会社",
    "ABC5株式会社",
    "ABC6株式会社",
    "ABC7株式会社",
    "ABC8株式会社",
    "ABC9株式会社",
    "ABC10株式会社",
  ];
  storeItems: Array<any> = [
    "A店",
    "B店",
    "C店",
    "D店",
    "E店",
    "F店",
    "G店",
    "H店",
    "I店",
    "J店",
  ];
  roleItems: Array<any> = [
    {
      label: "店舗スタッフ",
      value: "ShopUser",
    },
    {
      label: "店舗管理者",
      value: "ShopManager",
    },
    {
      label: "スーパーバイザー",
      value: "CorpSuperVisor",
    },
    {
      label: "企業管理者",
      value: "CorpAdmin",
    },
    {
      label: "システムユーザ",
      value: "SystemUser",
    },
    {
      label: "システム管理者",
      value: "SystemAdmin",
    },
  ];
  panel = null;
  accountEdit = false;
  accountDelete = false;
  accountDetail = false;
  accountPassword = false;
  headers: Array<any> = [
    {
      text: "ID",
      value: "Email",
    },
    {
      text: "所属",
      value: "Affiliation",
    },
    {
      text: "ロール",
      value: "CognitoGroup",
    },
    {
      text: "最終ログイン",
      value: "LastLoginDate",
    },
    {
      text: "メンテナンスカード",
      value: "MaintenanceCardId",
    },
    {
      text: "",
      value: "actions",
      align: "right",
      sortable: false,
    },
  ];
  loading = true;
  data: Array<any> = [];
  password = "";
  editedIndex = -1;
  editedItem: Record<string, any> = {
    id: "",
    company: "",
    store: "",
    roll: "",
    isValid: true,
    maintenanceCardId: "",
    userGroup: {
      id: "",
      name: "",
    },
  };
  defaultItem: Record<string, any> = {
    id: "",
    company: "",
    store: "",
    roll: "",
    isValid: true,
    maintenanceCardId: "",
    userGroup: {
      id: "",
      name: "",
    },
  };
  formTitle = this.editedIndex === -1 ? "新規アカウント" : "アカウント編集";

  async created() {
    await this.initialize();
    // this.getAccounts()
  }

  async initialize() {
    this.loading = true;
    if (!this.hasSystemPermission) this.company = this.user.companyId;
    this.company = this.$route.query.company
      ? this.$route.query.company
      : this.company;
    await Promise.all([
      this.getAccounts(this.company),
      this.loadShops(),
      this.fetchGroups(),
    ]);
    this.shopFilter(this.store);
    console.log("ACOUNTS", this.accounts);
    this.loading = false;
  }

  async loadShops() {
    if (this.hasSystemPermission) {
      this.clearShops();
      for (const company of this.companies) {
        await this.addShops(company.base.Id);
      }
    } else {
      await this.getShops(this.company);
    }
  }

  close() {
    this.accountEdit = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  closeDelete() {
    this.accountDelete = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  shopFilter(shopId: any) {
    if (this.store)
      this.userAccounts = this.accounts.filter((d) => d.ShopId == shopId);
    else this.userAccounts = this.accounts;
  }

  openDetail(item: any) {
    //this.editedIndex = this.data.indexOf(item);
    //this.editedItem = Object.assign({}, item);
    this.accountDetailInfo = item;
    console.log("SELECTED ACCOUNT", this.accountDetailInfo);
    this.accountDetail = true;
  }

  openPassword() {
    this.accountEdit = false;
    this.accountPassword = true;
  }

  getShopName(shopId: string) {
    const result = this.shops.filter((shop) => shop.base.Id === shopId);
    if (result.length == 0) return;
    return result[0].base.DisplayName;
  }

  getCompanyName(companyId: string) {
    const result = this.companies.filter(
      (company) => company.base.Id === companyId
    );
    if (result.length == 0) return;
    return result[0].base.DisplayName;
  }

  getGroupName(groupId: string) {
    const result = this.userGroups.filter((shop) => shop.base.Id === groupId);
    if (result.length == 0) return;
    return result[0].base.Name;
  }

  getRoleLabel(roleValue: any) {
    if (!roleValue) return;
    const result = this.roleItems.filter((role) => role.value === roleValue);
    return result[0].label;
  }
  unixTimeToLocalString(time: number): string {
    if (time) {
      const dt = new Date(
        moment.unix(time).utc().local().format("YYYY/MM/DD HH:mm:ss")
      );
      return `${dt.getFullYear()}年${("0" + (dt.getMonth() + 1)).slice(-2)}月${(
        "0" + dt.getDate()
      ).slice(-2)}日`;
    } else return "";
    //   return moment.unix(time).utc().local().format('YYYY/MM/DD HH:mm:ss');
  }
}
