



















import { Component, Vue } from 'vue-property-decorator';
import Navbar from './components/layouts/Navbar.vue';
import { Action, Getter } from 'vuex-class';

@Component({
  components: {
    Navbar,
  },
})
export default class App extends Vue {
  @Action('auth/authAction')
  authAction!: () => void;

  created() {
    this.authAction();
  }
}
