





































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { SystemPlannedMaintenanceSchedules } from "../../models/topup/systemPlannedMaintenanceSchedules.model";
import { v4 as uuidV4 } from "uuid";
import { AuthUser } from "@/models/internal/user.model";
import { LoggingOperationHistoryParam } from "@/store/SystemOperationHistories/systemOperationHistories";

@Component
export default class FunctionRestriction extends Vue {
  @Getter("auth/user") user!: AuthUser;
  @Getter('auth/isSystemAdmin') isSystemAdmin!: boolean;
  @Getter("systemPlannedMaintenanceSchedules/systemPlannedMaintenanceSchedules")
  systemPlannedMaintenanceSchedules!: SystemPlannedMaintenanceSchedules[];
  @Action(
    "systemPlannedMaintenanceSchedules/createNewSystemPlannedMaintenanceSchedules"
  )
  createNewSystemPlannedMaintenanceSchedules!: (payload: any) => void;
  @Action(
    "systemPlannedMaintenanceSchedules/updateSystemPlannedMaintenanceSchedules"
  )
  updateSystemPlannedMaintenanceSchedules!: (payload: any) => void;
  @Action(
    "systemPlannedMaintenanceSchedules/getSystemPlannedMaintenanceSchedules"
  )
  getSystemPlannedMaintenanceSchedules!: (payload: any) => void;
  @Action("systemOperationHistories/loggingOperationHistory")
  loggingOperationHistory!: (value: LoggingOperationHistoryParam) => void;

  restriction = {
    status: "false",
    text: "利用制限",
    isInRestriction: false,
    endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    endTime:
      new Date().toTimeString().split(" ")[0].split(":")[0] +
      ":" +
      new Date().toTimeString().split(" ")[0].split(":")[1],
  };

  modal = false;

  modal2 = false;

  minDate: string = new Date(
    Date.now() - new Date().getTimezoneOffset() * 60000
  )
    .toISOString()
    .substr(0, 10)
    .toString();

  showError = false;

  scheduleId = "";

  mainteSchedules = [];

  loading = false;

  disableDateTimeField = true;

  @Watch("user", { immediate: true, deep: true })
  async onUserChange() {
    await this.$store.dispatch(
      "systemPlannedMaintenanceSchedules/getSystemPlannedMaintenanceSchedules",
      null
    );
    // this.restriction.text = this.systemPlannedMaintenanceSchedules
    //   ? this.systemPlannedMaintenanceSchedules[0].Message
    //   : "表示中の文言";
  }

  @Watch("systemPlannedMaintenanceSchedules", { immediate: true, deep: true })
  async onsystemPlannedMaintenanceSchedulesChange() {
    if (this.systemPlannedMaintenanceSchedules[0]?.EndTimestampMs) {
      if (
        this.systemPlannedMaintenanceSchedules[0]?.StartTimestampMs <
          Date.now() &&
        this.systemPlannedMaintenanceSchedules[0]?.EndTimestampMs > Date.now()
      ) {
        this.restriction.isInRestriction = true;
      } else {
        this.restriction.isInRestriction = false;
      }
    } else {
      this.restriction.isInRestriction = false;
    }

    if (this.restriction.isInRestriction) {
      this.restriction.endDate = new Date(
        this.systemPlannedMaintenanceSchedules[0].EndTimestampMs
      )
        .toLocaleString("ja-JP")
        .split(" ")[0];
      this.restriction.endTime = new Date(
        this.systemPlannedMaintenanceSchedules[0].EndTimestampMs
      )
        .toLocaleString("ja-JP")
        .split(" ")[1]
        .substring(0, 5);
    }
    this.restriction.text = this.systemPlannedMaintenanceSchedules[0]
      ? this.systemPlannedMaintenanceSchedules[0].Message
      : "利用制限";

    if (this.restriction.isInRestriction) {
      this.disableDateTimeField = true;
    } else {
      if (this.isSystemAdmin) {
        this.disableDateTimeField = false;
      } else {
        this.disableDateTimeField = true;
      }
    }
  }

  async created() {
    await this.$store
      .dispatch(
        "systemPlannedMaintenanceSchedules/getSystemPlannedMaintenanceSchedules",
        null
      )
      .then(() => {
        this.mainteSchedules =
          this.$store.getters[
            "systemPlannedMaintenanceSchedules/systemPlannedMaintenanceSchedules"
          ];
        if (this.systemPlannedMaintenanceSchedules[0]?.EndTimestampMs) {
          if (
            this.systemPlannedMaintenanceSchedules[0]?.StartTimestampMs <
              Date.now() &&
            this.systemPlannedMaintenanceSchedules[0]?.EndTimestampMs >
              Date.now()
          ) {
            this.restriction.isInRestriction = true;
          } else {
            this.restriction.isInRestriction = false;
          }
        } else {
          this.restriction.isInRestriction = false;
        }
        this.restriction.text = this.systemPlannedMaintenanceSchedules[0]
          ? this.systemPlannedMaintenanceSchedules[0].Message
          : "利用制限";
      });
  }

  async clickRestriction(status: boolean) {
    this.restriction.status = status ? "true" : "false";
    this.showError = false;
    this.loading = true;

    if (!status) {
      // for (const s of this.systemPlannedMaintenanceSchedules) {
      const payload = {
        ScheduleId: this.systemPlannedMaintenanceSchedules[0].ScheduleId,
        StartTimestampMs:
          this.systemPlannedMaintenanceSchedules[0].StartTimestampMs,
        EndTimestampMs: 0,
        UserId: this.user.id,
        Message: this.restriction.text,
        UserName: this.user.email,
        CreatedAt: this.systemPlannedMaintenanceSchedules[0].CreatedAt,
      };
      await this.updateSystemPlannedMaintenanceSchedules(payload);
      // 操作履歴
      await this.loggingOperationHistory({
        user: this.user,
        viewName: "利用制限",
        viewId: "FunctionRestriction",
        operationName: "利用制限 終了",
        operationId: "endFunctionRestriction",
        detail: {
          message: this.restriction.text,
        },
      });
      // }
    } else {
      const endDateTime = new Date(
        this.restriction.endDate + ", " + this.restriction.endTime
      ).getTime();

      const payload = {
        ScheduleId: uuidV4(),
        StartTimestampMs: Date.now(),
        EndTimestampMs: endDateTime,
        UserId: this.user.id,
        Message: this.restriction.text,
        UserName: this.user.email,
        CreatedAt: Date.now(),
      };
      await this.createNewSystemPlannedMaintenanceSchedules(payload);
      // 操作履歴
      await this.loggingOperationHistory({
        user: this.user,
        viewName: "利用制限",
        viewId: "FunctionRestriction",
        operationName: "利用制限 開始",
        operationId: "startFunctionRestriction",
        detail: {
          message: this.restriction.text,
        },
      });
    }
    await this.$store.dispatch(
      "systemPlannedMaintenanceSchedules/getSystemPlannedMaintenanceSchedules",
      null
    );
    this.loading = false;
  }
}
