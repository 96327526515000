




































import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
  import { Action, Getter } from 'vuex-class';
  import { AuthUser } from '../../models/internal/user.model';

  @Component
  export default class LogsPayment extends Vue {
    dates: Array<string> = [];
    menu = false;
    logPayments: any[] = [];
    loading = true;
    search = '';
    searchStore: any = '';
    searchKind: any = '';
    searchCompany: any = '';
    searchTerminal: any = '';
    deviceEdit = false;
    deviceDelete = false;
    deviceDetail = false;
    headers = [
      { text: 'ステータス', value: 'Status' },
      { text: '日時', value: 'CreatedAt' },
      { text: '店舗', value: 'ShopId' },
      { text: 'チャージ機ID', value: 'TerminalId' },
      { text: '取引ID', value: 'Id' },
      { text: '種別', value: 'Card.Kind' },
      { text: '会員No.', value: 'Card.Id' },
      { text: '万', value: 'NoteClass.Jpy10000' },
      { text: '五', value: 'NoteClass.Jpy5000' },
      { text: '二千', value: 'NoteClass.Jpy2000' },
      { text: '千', value: 'NoteClass.Jpy1000' },
      { text: '金額', value: 'TotalAmount' },
    ];
    statusItems = [
      {
        label: '取引完了',
        value: 'succeeded',
      },
      {
        label: '取引中',
        value: 'trading',
      },
      {
        label: '電断',
        value: 'electricPower',
      },
      {
        label: '取引中止',
        value: 'canceled',
      },
      {
        label: '取引中',
        value: 'inProgress',
      },
      {
        label: '未知',
        value: 'unknown',
      },
    ];
    kindItems = [
      {
        label: 'QRコード',
        value: 'shiagel.qr',
      },
      {
        label: 'ICカード',
        value: 'shiagel.ic',
      },
      // TODO: 磁気カードに正式対応する際にコメントアウトを外す
      // {
      //   label: '磁気カード',
      //   value: 'shiagel.mag',
      // },
    ]

    @Action('transactions/getTransactions') getTransactions!: (payload: {
      companyId: string;
      shopId?: string;
    }) => void;
    @Getter('transactions/transactions') transactions!: any;

    @Getter('auth/user') user!: AuthUser;
    @Getter('auth/hasSystemPermission') hasSystemPermission!: boolean;
    @Action('shops/getShops') getShops!: (companyId: string) => void;
    @Getter('shops/shops') shops!: any[];

    async created() {
      this.loading = true;
      this.searchCompany = this.$route.query.company
        ? this.$route.query.company
        : undefined;

      this.searchStore = this.$route.query.store
        ? this.$route.query.store
        : undefined;

      this.searchTerminal = this.$route.query.terminal
        ? this.$route.query.terminal
        : undefined;

      // if (this.hasSystemPermission) {
      //   if(!this.searchCompany) {
      //     await this.getTransactions(payload);
      //   }
      // }
      const payload = {
        companyId: this.searchCompany,
        shopId: this.searchStore,
        terminalId: this.searchTerminal,
      };
      await this.getTransactions(payload);
      await this.getShops(this.searchCompany);
      this.logPayments = this.sortByDate(this.transactions);
      this.loading = false;
    }

    private sortByDate(transaction: any) {
       return transaction.sort(function(x: any, y: any) {
        return y.CreatedAt-x.CreatedAt;
      });
    }

    unixTimeToLocalString(time: number): string {
        if(time) {
            const dt = new Date(moment.unix(time).utc().local().format('YYYY/MM/DD HH:mm:ss'));
            return `${dt.getFullYear()}年${("0" + (dt.getMonth() + 1)).slice(-2)}月${("0" + dt.getDate()).slice(-2)}日 ${dt.getHours()}:${dt.getMinutes()}:${dt.getSeconds()}`;
        } else
            return ""
    }

    async searchFilter() {
      let result: any[] = this.transactions;

      if(this.searchStore)
        result = result.filter((t) => t.ShopId == this.searchStore);

      if(this.searchKind)
        result = result.filter((t) => t.Card.Kind == this.searchKind);

      if(this.dates.length > 0 ) {
        result = result.filter((t) => this.isDateExist(t.CreatedAt,this.dates));
      }

      this.logPayments = result;
    }

    isDateExist(date: string, dates: string[]): boolean {
      const dt = new Date(date);

      date = `${dt.getFullYear()}-${("0" + (dt.getMonth() + 1)).slice(-2)}-${("0" + dt.getDate()).slice(-2)}`
      let to = dates[1], from = dates[0];
      if(dates[0] > dates[1]) {
        to = dates[0], from = dates[1];
      }
      if(date <= to && date >= from) return true;
      return false;
    }

    getShopName(shopId: string) {
      const result = this.shops.filter((shop) => shop.base.Id === shopId);
      if (result.length == 0) return;
      return result[0].base.DisplayName;
    }

    getStatusLabel(value: any): string | void {
      if (!value) return;
      const result = this.statusItems.filter(
        (status) => status.value === value
      );
      if (result == undefined || result[0] == undefined) return 'unknown'; //もし知らないステータスが出てきたら、未知にする。
      return result[0].label;
    }

    getCardKind(cardKind: string) {
      switch(cardKind) {
        case 'shiagel.qr': return 'QRコード'
        case 'shiagel.ic': return 'ICカード'
        case 'shiagel.mag': return '磁気カード'
        default: return cardKind;
      }
    }
  }
