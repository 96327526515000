import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { Auth } from 'aws-amplify';
import { auth } from '@/store/auth';
// import { company as companyStore } from '@/store/Company/company';
import Dashboard from '../views/dashboard/Dashboard.vue';
import Account from '../views/account/Account.vue';
import Company from '../views/company/Company.vue';
import Store from '../views/store/Store.vue';
import Group from '../views/group/Group.vue';
import GroupUser from '../views/group/user/GroupUser.vue';
import GroupDevice from '../views/group/device/GroupDevice.vue';
import Customer from '../views/customer/Customer.vue';
import Management from '../views/management/Management.vue';
import FunctionRestriction from '../views/management/FunctionRestriction.vue';
import Notification from '../views/management/Notification.vue';
import Trouble from '../views/management/Trouble.vue';
import Logs from '../views/logs/Logs.vue';
import LogsDevice from '../views/logs/LogsDevice.vue';
import LogsOperation from '../views/logs/LogsOperation.vue';
import LogsDeviceOperation from '../views/logs/LogsDeviceOperation.vue';
import LogsPayment from '../views/logs/LogsPayment.vue';
import Device from '../views/device/Device.vue';
import Discontinuation from '../views/device/Discontinuation.vue';
import DeviceTrouble from '../views/device/DeviceTrouble.vue';
import Operation from '../views/device/operation/Operation.vue';
import OperationLogo from '../views/device/operation/OperationLogo.vue';
import OperationTaxOffice from '../views/device/operation/OperationTaxOffice.vue';
import HealthCheck from '../views/device/healthCheck/HealthCheck.vue';
import HealthCheckResult from '../views/device/healthCheck/HealthCheckResult.vue';
import DeviceMaintenance from '../views/device/maintenance/DeviceMaintenance.vue';
import DeviceMaintenanceConnect from '../views/device/maintenance/DeviceMaintenanceConnect.vue';
import DeviceMaintenanceHistory from '../views/device/maintenance/DeviceMaintenanceHistory.vue';
import Login from '../views/login/Login.vue';
import ForgotPassword from '../views/login/ForgotPassword.vue';
import PasswordReset from '../views/login/PasswordReset.vue';
import Maintenance from '../views/maintenance/Maintenance.vue';
import UserSetting from '../views/user/UserSetting.vue';
import Sales from '../views/sales/Sales.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: { requiresAuth: true },
  },
  {
    path: '/company',
    name: 'Company',
    component: Company,
    meta: { requiresAuth: true },
  },
  {
    path: '/store',
    name: 'Store',
    component: Store,
    meta: { requiresAuth: true },
  },
  {
    path: '/group',
    name: 'Group',
    component: Group,
    meta: { requiresAuth: true },
  },
  {
    path: '/group/user',
    name: 'Group-User',
    component: GroupUser,
    meta: { requiresAuth: true },
  },
  {
    path: '/group/device',
    name: 'Group-Device',
    component: GroupDevice,
    meta: { requiresAuth: true },
  },
  {
    path: '/customer',
    name: 'Customer',
    component: Customer,
    meta: { requiresAuth: true },
  },
  {
    path: '/management',
    name: 'Management',
    component: Management,
    meta: { requiresAuth: true },
  },
  {
    path: '/management/functionRestriction',
    name: 'Function-Restriction',
    component: FunctionRestriction,
    meta: { requiresAuth: true },
  },
  {
    path: '/management/notification',
    name: 'Notification',
    component: Notification,
    meta: { requiresAuth: true },
  },
  {
    path: '/management/trouble',
    name: 'Trouble',
    component: Trouble,
    meta: { requiresAuth: true },
  },
  {
    path: '/logs',
    name: 'Logs',
    component: Logs,
    meta: { requiresAuth: true },
  },
  {
    path: '/logs/device',
    name: 'Logs-Device',
    component: LogsDevice,
    meta: { requiresAuth: true },
  },
  {
    path: '/logs/operation',
    name: 'Logs-Operation',
    component: LogsOperation,
    meta: { requiresAuth: true },
  },
  {
    path: '/logs/payment',
    name: 'Logs-Payment',
    component: LogsPayment,
    meta: { requiresAuth: true },
  },
  {
    path: '/logs/device-operation',
    name: 'Logs-Device-Operation',
    component: LogsDeviceOperation,
    meta: { requiresAuth: true },
  },
  {
    path: '/device',
    name: 'Device',
    component: Device,
    meta: { requiresAuth: true },
  },
  {
    path: '/device/discontinuation',
    name: 'Device-Discontinuation',
    component: Discontinuation,
    meta: { requiresAuth: true },
  },
  {
    path: '/device/trouble',
    name: 'Device-Trouble',
    component: DeviceTrouble,
    meta: { requiresAuth: true },
  },
  {
    path: '/device/operation',
    name: 'Device-Operation',
    component: Operation,
    meta: { requiresAuth: true },
  },
  {
    path: '/device/operation/logo',
    name: 'Device-Operation-Logo',
    component: OperationLogo,
    meta: { requiresAuth: true },
  },
  {
    path: '/device/operation/taxisOffice',
    name: 'Device-Operation-TaxOffice',
    component: OperationTaxOffice,
    meta: { requiresAuth: true },
  },
  {
    path: '/device/healthCheck',
    name: 'Device-HealthCheck',
    component: HealthCheck,
    meta: { requiresAuth: true },
  },
  {
    path: '/device/healthCheck/result',
    name: 'Device-HealthCheck-Result',
    component: HealthCheckResult,
    meta: { requiresAuth: true },
  },
  {
    path: '/device/maintenance',
    name: 'Device-Maintenance',
    component: DeviceMaintenance,
    meta: { requiresAuth: true },
  },
  {
    path: '/device/maintenance/connect',
    name: 'Device-Maintenance-Connect',
    component: DeviceMaintenanceConnect,
    meta: { requiresAuth: true },
  },
  {
    path: '/device/maintenance/history',
    name: 'Device-Maintenance-History',
    component: DeviceMaintenanceHistory,
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/login/forgotPassword',
    name: 'Login-ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/login/passwordReset',
    name: 'Login-PasswordReset',
    component: PasswordReset,
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance,
    meta: { requiresAuth: true },
  },
  {
    path: '/user/setting',
    name: 'User-Setting',
    component: UserSetting,
    meta: { requiresAuth: true },
  },
  {
    path: '/sales',
    name: 'Sales',
    component: Sales,
    meta: { requiresAuth: true },
  },
  /*
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
  },
  {
    path: '/terminals',
    name: 'Terminals',
    component: Terminals,
    meta: { requiresAuth: true },
  },
  */
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

//checks auth whenever going from one page to another
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  //checks on server everytime the app changes route
  const isAuthenticated = await Auth.currentUserInfo();

  //check when on server only when the page is reloaded
  //then it only checks the state
  //const isAuthenticated = auth.state.user.isAuthenticated;
  //console.log('autenticado?', auth.state.user.isAuthenticated);
  // console.log('requiresAuth', requiresAuth);
  console.log('isAuthenticated', auth.state.isAuthenticated);

  if (requiresAuth && !isAuthenticated) {
    next('/login');

  } else if (requiresAuth && isAuthenticated) {
    let mainteSchedules = [];
    let isInRestriction = false;
    await router.app.$store.dispatch('company/getCompany');
    await router.app.$store.dispatch('systemPlannedMaintenanceSchedules/getSystemPlannedMaintenanceSchedules', null)
      .then(() => {
        mainteSchedules = router.app.$store.getters['systemPlannedMaintenanceSchedules/systemPlannedMaintenanceSchedules'];
        if (mainteSchedules[0].EndTimestampMs) {
          if (
            mainteSchedules[0]?.StartTimestampMs <
            Date.now() &&
            mainteSchedules[0]?.EndTimestampMs >
            Date.now()
          ) {
            isInRestriction = true;
          } else {
            isInRestriction = false;
          }
        } else {
          isInRestriction = false;
        }
      });

    // 機能制限が発動中の場合はシステム管理者以外は制限中画面へ遷移する
    const isSystemAdmin = auth.state.user?.role === 'SystemAdmin'; // TODO: auth storeにまとめたい
    if (isInRestriction && !isSystemAdmin && to.name !== 'Function-Restriction') {
      next('/management/functionRestriction');
    }

    next();

  } else {
    next();
  }
});

export default router;
