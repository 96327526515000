import GraphQL from '@/interfaces/graphql';
import {
  getTransactions,
  listTransactions,
} from '@/CustomQueries/transactions.queries';
import { createTransactions } from '@/graphql/mutations';
// import { Company } from '@/models/commadmin/companies.model';
import { TerminalEntity } from '@/models/internal/terminalEntity.model';
// import CommAdminApi from '@/interfaces/commAdminApi';
// import { getJwt } from '@/store/auth/auth';

interface TransactionFilter {
  // CompanyId: {
  //   eq: string;
  // };
  // ShopId?: {
  //   eq: string;
  // };
  TerminalId?: {
    eq: string;
  };
}
export const transactions = {
  namespaced: true,
  state: { error: null, transactions: [] },
  mutations: {
    setTransactions(state: any, payload: any) {
      state.transactions = payload;
    },
    setTransaction(state: any, payload: any) {
      state.transactions.push(payload);
    },
  },
  actions: {
    async getTransactions(
      { commit, rootState, dispatch }: any,
      payload: { companyId?: string; shopId?: string; terminalId?: string }
    ) {
      // TODO: [fkmy] companyId, shopId による絞り込みが必要
      console.log('getTransactions:', payload);
      console.log(rootState.terminals.terminals);
      if (rootState.terminals.terminals.length == 0) {
        await dispatch('terminals/getTerminals', { companyId: payload.companyId }, { root: true });
      }
      const terminalIds: string[] = [];
    //   rootState.terminals.terminals.map((v: TerminalEntity) => v.base.Id);
      if (payload.terminalId) { // 特定店舗のみを表示する場合
        terminalIds.push(payload.terminalId);
      } else {
          for(const t of rootState.terminals.terminals)
          terminalIds.push(t.base.Id);
      }
      const results: any[] = [];
      commit('setTransactions', []);
      const limit = 1000;
      for (const terminalId of terminalIds) {
        const filter: TransactionFilter = {
          TerminalId: { eq: terminalId },
        };
        // NOTE: GraphQL側にはshopIdとcompanyIdは無い
        // FIXME: [fkmy] この２行が機能してないっぽいので、やり直す必要ある
        // if (payload.shopId) filter.ShopId = { eq: payload.shopId };
        // if (payload.terminalId) filter.TerminalId = { eq: payload.terminalId };
        try {
          console.log('listTransactions', filter);
          const transactions = await GraphQL.query(listTransactions, { filter, limit });
          console.log(transactions, transactions.data.listTransactions.items.length);
          for (const item of transactions.data.listTransactions.items) {
            item.TotalAmount = item.TotalAmount.toLocaleString();
            // results.push(item);
            commit('setTransaction', item);
          }
        } catch (error) {
          console.error('Get transactions error', error);
        }
      }
      // commit('setTransactions', results);
    },
  },
  getters: {
    transactions: (state: any) => state.transactions,
  },
};
