import CommAdminApi from '@/interfaces/commAdminApi';
import { getJwt } from '@/store/auth';
import { User } from '@/models/commadmin/users.model';
import { CompanyEntity } from '@/models/internal/companyEntity.model';

export interface UsersState {
  users: User[];
}

const state: UsersState = {
  users: [],
};

export const users = {
  namespaced: true,
  state,
  mutations: {
    setUsers(state: UsersState, payload: User[]) {
      state.users = payload;
    },    
  },
  actions: {
    async getUsers({ commit, dispatch, rootGetters }: any) {
      // ログイン中のユーザが参照可能な企業を全て読み込む
      await dispatch('company/getCompany', null, { root: true });
      const companies: CompanyEntity[] = rootGetters['company/companies']

      const authKey = await getJwt();
      const api = new CommAdminApi(authKey);
      const entities: User[] = [];
      for (const company of companies) {
        // const authKey = rootState.auth.token;
        const users = await api.getCompanyUsers(company.base.Id);
        for (const user of users) {
          entities.push(user);
        }
      }
      commit('setUsers', entities);
    },    
  },
  getters: {
    users: (state: UsersState) => state.users,
  },
};
