












































  import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
  import { Action, Getter } from 'vuex-class';
import { CompanyEntity } from '../../models/internal/companyEntity.model';


  @Component
  export default class Company extends Vue {
    @Action('company/getCompany') getCompany!: (force?: boolean) => Promise<void>;
    @Getter('company/companies') companies!: CompanyEntity[];
    @Getter('company/myCompany') myCompany!: CompanyEntity | null;

    headers = [
      {
        text: 'ID',
        value: 'base.Id',
      },
      {
        text: '企業',
        value: 'base.DisplayName',
      },
      {
        text: '作成日',
        value: 'base.CreatedAt',
      },
    ];
    search = '';
    companyDetails = false;
    currentCompanyInfo: any = {};
    /** 削除済み企業を表示するかどうか */
    isShowDeleted = false;
    // NOTE: 共通管理と同じように削除済みを表示する必要が出た場合のために準備
    loading = true;
    /** リストに表示する企業リスト */
    get companyList() {
      this.loading = true;
      if (this.isShowDeleted) {
        this.loading = false;
        return this.companies;
      } else {
        // 削除済みを表示しない
        this.loading = false;
        return this.companies.filter(v => v.base.IsDelete == false);
      }

    }
    convertToLocaleString(value: any): string {
        if(value) {
            const dt = new Date(moment.unix(value).utc().local().format('YYYY/MM/DD HH:mm:ss'));
            return `${dt.getFullYear()}年${("0" + (dt.getMonth() + 1)).slice(-2)}月${("0" + dt.getDate()).slice(-2)}日`;
        } else
            return ""
    }

    openDetail(item: any) {
        this.currentCompanyInfo = item.base;
        this.companyDetails = true;
    }
    async created() {
      this.loading = true;
      //get the company data
      await this.getCompany();
      console.log('company', this.companies);
      this.loading = false;
    }
  }
